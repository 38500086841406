import React, { Component, Suspense, lazy } from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import ScrollToTop from './components/scrollToTop';
import { ProtectedRoute } from './components/ProtectedRoute';

import UserProvider from './providers/UserProvider';
import RemoteConfigProvider from './providers/RemoteConfigProvider';

import NavBar from './components/NavBar';
import Footer from './components/Footer';

import AboutPage from './pages/About';
import FAQs from './pages/FAQs';
import ForgotPassword from './pages/ForgotPassword';
// import GiftRedeem from './pages/GiftRedeem';
// import GiftSuccess from './pages/GiftSuccess';
// import GiftsPage from './pages/Gifts';
// import GivingPage from './pages/Giving';
import LandingPage from './pages/Landing';
import LibraryPage from './pages/Library';
import LoginPage from './pages/LogIn';
import PrivacyPage from './pages/Privacy';
import ContentRequirements from './pages/ContentRequirements';
import Series from './components/series/Series';
import SplashPage from './pages/Splash';
import SubscriptionPage from './pages/Subscribe';
import TermsPage from './pages/Terms';
// import Creators from './pages/Creators';

// import AccountHeader from './components/account/Account.Header';
// import Dashboard from './components/account/AccountDashboard';
// import UserProfile from './components/account/AccountProfile';
// import UserBilling from './components/account/AccountBilling';
// import CreatorDashboard from './components/creator/CreatorDashboard';

import { ListenHistoryProvider } from "./providers/ListenHistoryProvider";
// import GiftCancel from "./pages/GiftCancel";
import SubscribeCancel from "./pages/SubscribeCancel";
import SubscribeSuccess from "./pages/SubscribeSuccess";
import SubscribeStepOne from "./pages/SubscribeStepOne";
import SubscribeStepTwo from "./pages/SubscribeStepTwo";
import SubscribeStepThree from "./pages/SubscribeStepThree";
// import CreatorSignUp from "./pages/CreatorSignUp";
import SiteMap from './pages/SiteMap';
import FirstVisitPopUp from './components/FirstVisitPopUp';

import "./theme.scss";
import "./App.scss";

const AccountHeader = lazy(() => import('./components/account/Account.Header'));
const Dashboard = lazy(() => import('./components/account/AccountDashboard'));
const UserProfile = lazy(() => import('./components/account/AccountProfile'));
const UserBilling = lazy(() => import('./components/account/AccountBilling'));
// const CreatorDashboard = lazy(() => import('./components/creator/CreatorDashboard'));


class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <RemoteConfigProvider>
                    <UserProvider>
                        <ListenHistoryProvider>
                            <ScrollToTop>
                                <Switch>
                                    <Route exact path="/splash" component={SplashPage} />
                                    <Route path="/" component={NavBar} />
                                </Switch>

                                <div style={{ paddingTop: 63 }}>
                                    <FirstVisitPopUp />

                                    <Switch>
                                        <Redirect exact from="/" to="/home" />
                                        <Route path="/about" component={AboutPage} />
                                        <Route path="/faq" component={FAQs} />
                                        <Route path="/forgotpassword" component={ForgotPassword} />
                                        {/* <Route path="/gift-redeem" component={GiftRedeem}/> */}
                                        {/* <Route path="/gift-success" component={GiftSuccess}/> */}
                                        {/* <Route path="/gift-cancel" component={GiftCancel}/> */}
                                        {/* <Route path="/gifts" component={GiftsPage}/> */}
                                        {/* <Route path="/giving" component={GivingPage} /> */}
                                        <Route path="/home" component={LandingPage} />
                                        <Route path="/login" component={LoginPage} />
                                        <Route path="/privacy" component={PrivacyPage} />
                                        <Route path="/content-requirements" component={ContentRequirements} />
                                        <Route path="/terms" component={TermsPage} />
                                        <Route path="/library" component={LibraryPage} />
                                        <Route path="/series/:seriesType/:title/:seriesId" component={Series} />
                                        <Route path="/series/:seriesId" component={Series} />
                                        <Route path="/subscribe" component={SubscriptionPage} />
                                        <Route path="/subscribe-steps-1" component={SubscribeStepOne} />
                                        <Route path="/subscribe-steps-2" component={SubscribeStepTwo} />
                                        <Route path="/subscribe-steps-3" component={SubscribeStepThree} />
                                        <Route path="/subscribe-cancel" component={SubscribeCancel} />
                                        <Route path="/subscribe-success" component={SubscribeSuccess} />
                                        {/* <Route path="/creators" component={Creators} /> */}
                                        {/* <Route path="/creator-sign-up" component={CreatorSignUp} /> */}
                                        <Route path="/storier-site-map" component={SiteMap} />
                                    </Switch>

                                <Suspense fallback={<div>Loading...</div>}>
                                    <ProtectedRoute path="/account" component={AccountHeader} />
                                    <ProtectedRoute exact path="/account/dashboard" component={Dashboard} />
                                    <ProtectedRoute path="/account/profile" component={UserProfile} />
                                    <ProtectedRoute path="/account/billing" component={UserBilling} />
                                    {/* <ProtectedRoute path="/creator" component={CreatorDashboard} /> */}
                                </Suspense>

                                </div>

                                <Switch>
                                    <Route exact path="/" component={() => null} />
                                    <Route path="/" component={Footer} />
                                </Switch>
                            </ScrollToTop>
                        </ListenHistoryProvider>
                    </UserProvider>
                </RemoteConfigProvider>
            </BrowserRouter>
        );
    }
}

export default App;
