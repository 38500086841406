import React, { useRef, useEffect } from 'react';
import { ReactComponent as StorierSafeIcon } from '../assets/icons/storier-safe-icon.svg';
import Row from "react-bootstrap/Row";

import './StorierSafe.scss';

const StorierSafe = () => {
	const containerRef = useRef();
	const iconRef = useRef();
	const rotMax = 180;

	const resize = () => {
		const height = window.innerHeight;
		const gridTop = height * (height > window.innerWidth ? -1 : -.6);
		const gridBottom = height * .8;

		const container = containerRef.current;
		const thisTop = container.offsetTop - window.scrollY;

		let rotation = -rotMax;
		if (thisTop >= gridTop && thisTop <= gridBottom) {
			const delta = gridBottom - gridTop;
			rotation = (((thisTop - gridTop) / delta) - .5) * 2 * -rotMax;
		} else if (thisTop < gridTop) {
			rotation = rotMax
		}

		if (rotation > 0) {
			rotation = 0;
		}

		iconRef.current.style.transform = `rotate(${rotation}deg)`
		document.getElementById('storier-safe-inner').style.transform = `rotate(${-rotation}deg)`;
	}

	useEffect(() => {
		resize();
		document.addEventListener('scroll', resize);

		return () => {
			document.removeEventListener('scroll', resize);
		}
	}, []);

	return (
		<div className="storier-safe-section" ref={containerRef}>
			<Row className="col-xl-10 col-lg-12 mx-auto h-50 row-1 position-relative">
				<div className="col-lg-4 storier-safe-col">
					<div className="content-section safe-step-1">
						<h3 className="text-blue">Select Content for Ages 0-12</h3>
						<p>
							Our content partners know our mission and see the need for a kid-safe audio service.
							They help us by only transferring us what they deem to be their kid-friendly content.
						</p>
					</div>
				</div>
				<div className="col-lg-4 storier-safe-col">
					<StorierSafeIcon className="storier-safe-icon rotate" ref={iconRef} />
				</div>
				<div className="col-lg-4 storier-safe-col">
					<div className="content-section safe-step-2">
						<h3 className="text-yellow">Ad-Free Feeds</h3>
						<p>
							Storytime shouldn't have commercial breaks. We require all of our content creators to
							remove any ads and sponsors from their shows.
						</p>
					</div>
				</div>
			</Row>
			<Row className="col-xl-10 col-lg-12 row-2 mx-auto">
				<div className="col-lg-4 storier-safe-col">
					<div className="content-section safe-step-4">
						<h3 className="text-purple">Enhanced by Users Like You</h3>
						<p>
							If a listener ever finds content they believe does not belong in a kid's world,
							they can easily report it right on our platform. We then send it back through
							our approval process for re-screening.
						</p>
					</div>
				</div>
				<div className="col-lg-4 storier-safe-col">

				</div>
				<div className="col-lg-4 storier-safe-col">
					<div className="content-section safe-step-3">
						<h3 className="text-red">Filtered by Storier Team</h3>
						<p>
							Every book, album, and podcast is reviewed by real humans before
							it is made accessible to Storier listeners.
						</p>
					</div>
				</div>
			</Row>
		</div>
	)
}

export default StorierSafe;