import React, { useEffect, useState } from "react";
import { storage } from "../../firebase";

import './CoverArt.scss';
import Spinner from "../Spinner";

//TODO: support loading animation

const iconType = fm_type => {
  const icon = type => (
    <i
      className={`fas text-white ${type}`}
      style={{
        fontSize: 25,
        marginRight: 5,
        textShadow: `1px 1px 4px rgba(0, 0, 0, .5)`
      }}
    />
  );
  switch (fm_type) {
    case 'podcast':
      return icon('fa-microphone-alt');
    case 'music':
      return icon('fa-music');
    case 'audiobook':
      return icon('fa-book-open');
    default:
      return null;
  }
};

const CoverArt = ({ url, alt, type, large }) => {

  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (url) {
      storage.ref(url).getDownloadURL()
        .then(downloadUrl => {
          setSrc(downloadUrl);
        })
        .catch(error => {
          console.error(error);
          // setSrc(window.ASSETS + "/app-ui/gradient-book-icon.png");
          setSrc("/icons/img/gradient-book-icon.png");
        });
    } else {
      // setSrc(window.ASSETS + "/app-ui/gradient-book-icon.png");
      setSrc("/icons/img/gradient-book-icon.png");
    }
  }, [url]);

  const titleDisplay = url ? '' : (
    <div
      className={`w-100 p-2 text-center text-white d-block`}
      style={{
        position: 'absolute',
        top: large ? '70%' : '61%',
      }}
    >
      {alt}
    </div>
  );


  return (
    <div className='coverArt rounded-lg border'>
      <div style={{ position: 'absolute', right: 10, bottom: 10, zIndex: 1 }}>
          {iconType(type)}
      </div>
      {titleDisplay}
      <Spinner fetching={!src} adjust={true}>
        <img src={src} alt={alt} />
      </Spinner>
    </div>
  );
};

export default CoverArt;
