import { functions } from './firebase';

export const makeId = length => {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const titleToLink = title => {
    const charactersToRemove = [";", ",", "/", "?", ":", "@", "&", "=", "+", "$", "_", ".", "!", "~", "*", "'", "(", ")", "#"];
    let tempTitle = title.replaceAll(' ', '-').toLowerCase();
    charactersToRemove.forEach(character => {
        tempTitle = tempTitle.replaceAll(character, '');
    });
    return tempTitle;
}

export const getSearchKey = async () => {
    let cachedSearchKey = window.localStorage.getItem('searchKey');
    let cachedSearchKeyTime = window.localStorage.getItem('searchKeyTime');
    let within7Days = false;

    /* Cache the search key for 7 days */
    if (cachedSearchKeyTime) {
        const oldDate = new Date(cachedSearchKeyTime);
        const today = new Date();

        const differenceInDays =
            (today.getTime() - oldDate.getTime()) / (1000 * 3600 * 24);

        if (differenceInDays < 7) {
            within7Days = true;
        }
    }

    if (
        cachedSearchKey &&
        cachedSearchKeyTime &&
        cachedSearchKeyTime &&
        within7Days
    ) {
        return cachedSearchKey;
    } else {
        const getSearchKey = functions.httpsCallable('getSearchKey');

        try {
            const result = await getSearchKey();

            if (result && result.data && result.data.length) {
                window.localStorage.setItem('searchKey', result.data);
                window.localStorage.setItem('searchKeyTime', new Date().toISOString());
                return result.data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }
};

export const collectIdsAndDocs = doc => {
    return { id: doc.id, ...doc.data() };
};

/**
 * Shuffles array in place.
 * from :: https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export const isCreatorStripeInProgress = user => {
    return user && user.private && user.private.payoutAccount;
};

export const isCreatorStripeComplete = user => {
    return user && user.private && user.private.payoutAccount && user.private.payoutAccount.details_submitted;
};

export const pricing = {
    subscription: {
        perMonth: 8,
        per6Month: 44,
        perYear: 80,
    },
    gift: {
        per3Month: 24,
        per6Month: 44,
        per12Month: 80,
        per24Month: 152,
    },
};

export const plansInfo = {
    'basic': {
        monthly: {
            price: 6,
            productId: 'BASIC_MONTHLY',
            sales_tax: 0.43
        },
        annually: {
            price: 60,
            productId: 'BASIC_YEARLY',
            sales_tax: 4.35
        }
    },
    'extra': {
        monthly: {
            price: 9,
            productId: 'EXTRA_MONTHLY',
            sales_tax: 0.65
        },
        annually: {
            price: 90,
            productId: 'EXTRA_YEARLY',
            sales_tax: 6.52
        }
    },
    'super': {
        monthly: {
            price: 12,
            productId: 'SUPER_MONTHLY',
            sales_tax: 0.87
        },
        annually: {
            price: 120,
            productId: 'SUPER_YEARLY',
            sales_tax: 8.70
        }
    }
}

export const sendSlackMessage = async (message, channel) => {
    const sendSlackMessage = functions.httpsCallable('sendSlackMessage');

    try {
        await sendSlackMessage({
            text: message,
            channel: channel
        });
    } catch (err) {
        console.log(err);
    }
}

export const AGE_GROUPS = [
    { label: 'Ages 0-1', name: 'ages-0-to-1' },
    { label: 'Ages 2-3', name: 'ages-2-to-3' },
    { label: 'Ages 4-6', name: 'ages-4-to-6' },
    { label: 'Ages 7-12', name: 'ages-7-to-12' }
]

export const APP_STORE_LINK = "https://apps.apple.com/us/app/storier-audio-for-kids/id1585050099";
export const GOOGLE_STORE_LINK = "https://play.google.com/store/apps/details?id=com.storier.native.android";