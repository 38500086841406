import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../firebase.js";

// Assets
import './SubscribeSteps.scss';
import stepThreeCircles from '../assets/subscribing/stepThreeCircles.svg';
import SubscriptionPayment from '../components/SubscriptionPayment';
import qs from "qs";

const SubscribeStepThree = ({location}) => {

    const history = useHistory();
    const [planType, setPlanType] = useState('');
    const [paymentType, setPaymentType] = useState('');

    // Grab plantype and user info
    useEffect(() => {
        const { planType, paymentType } = qs.parse(location.search, {ignoreQueryPrefix: true});

        if (!planType || !paymentType) {
            history.push('/subscribe');
        }

        setPlanType(planType);
        setPaymentType(paymentType);
    }, [location, history]);

    return (
        <div className="content px-3">
            <div className={`hdr text-blue`}>
                <div className="circles-svg">
                    <img src={stepThreeCircles} alt="Step 2 graphic" />
                </div>
            </div>

            <div className="px-3">
                <Elements stripe={stripePromise}>
                    <SubscriptionPayment 
                        planType={planType}
                        paymentType={paymentType}
                    />
                </Elements>
            </div>
        </div>
    )
}

export default SubscribeStepThree;