import React from "react";

const Socials = () => {
  return (
    <div className="mt-4">
      <span className="mr-4">
        <a href="https://www.instagram.com/storier/">
          <i className="fab fa-instagram fa-lg" style={{color: '#e4405f'}} />
        </a>
      </span>
      <span className="mr-4">
        <a href="https://www.facebook.com/Storier-100421635054112/">
          <i className="fab fa-facebook-square fa-lg"  style={{color: '#537bbe'}} />
        </a>
      </span>
      <span className="mr-4">
        <a href="https://www.pinterest.com/StorierApp/">
          <i className="fab fa-pinterest fa-lg"  style={{color: '#cd2129'}} />
        </a>
      </span>
      <span className="mr-4">
        <a href="https://www.linkedin.com/company/storier/">
          <i className="fab fa-linkedin fa-lg"  style={{color: '#137bb8'}} />
        </a>
      </span>
    </div>
  )
};

export default Socials;