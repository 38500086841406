import React from "react";
import "./Spinner.scss";


const Spinner = ({ fetching, children, adjust }) => {
  if (fetching) {
    return (
      <div
        className="animated fadeIn text-center d-flex align-items-center justify-content-center"
        style={{ height: adjust ? "unset" : "80vh" }}
      >
        <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {children}
      </>
    );
  }
};

export default Spinner;
