import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

// Assets
import './SubscribeSteps.scss';
import stepOneCircles from '../assets/subscribing/stepOneCircles.svg';
import { UserContext } from "../providers/UserProvider";
import { plansInfo } from '../utilities';


const SubscribeStepOne = ({ location }) => {
    const planType = location.state;

    const [selected, setSelected] = useState('');
    const user = useContext(UserContext);

    const getLastDayToCancel = () => {
        const dateformat = require('dateformat');
        const date = new Date();
        date.setDate(date.getDate() + 30);

        return dateformat(date, "mmmm dS, yyyy");
    };

    const getNextStep = () => {
        if (user)
            return "/subscribe-steps-3";
        else
            return "/subscribe-steps-2";
    }

    const getPaymentOptions = () => {
        return (
            <div className="options-container">
                <button
                    className={`opt-btn ${planType} ${selected === 'monthly' ? 'selected' : ''}`}
                    onClick={() => setSelected('monthly')}
                >
                    <div>{`$${plansInfo[planType].monthly.price}`}</div>
                    <div>{"Per Month"}</div>
                </button>
                <button
                    className={`opt-btn ${planType} ${selected === 'annually' ? 'selected' : ''}`}
                    onClick={() => setSelected('annually')}
                >
                    <div>{`$${plansInfo[planType].annually.price}`}</div>
                    <div>{"Per Year"}</div>
                </button>
            </div>
        )
    }

    return (
        <div className="content px-3">
            <div className={`hdr ${planType}`}>
                <div className="circles-svg">
                    <img src={stepOneCircles} alt="Step 1 graphic" />
                </div>
            </div>

            <div className="page-body">
                <h2>Pay Annually to get 2 Months Free</h2>
                {getPaymentOptions()}
                <p className={`cancel-note ${planType}`}>
                    You can change this any time. Cancel before {getLastDayToCancel()} to not be charged.
                </p>
                <div className="continue-btn-container">
                    <Link
                        disabled={!selected}
                        className={`continue-btn ${planType}`}
                        to={{
                            pathname: getNextStep(),
                            search: `?planType=${planType}&paymentType=${selected}`,
                        }}
                    >
                        <span>CONTINUE</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SubscribeStepOne;