import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { auth, functions } from "../firebase";
import withUser from "./withUser";


class EmailLogin extends Component {
  state = {
    email: "",
    password: "",
    user: null,
    error_email: false,
    error_password: false,
    error_wrong_password: false,
    unknown_error: false
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { email, password } = this.state;

    if (email.length < 6) {
      this.setState({ error_email: true });
      return;
    }

    if (password.length < 6) {
      this.setState({ error_password: true });
      return;
    }

    auth.signInWithEmailAndPassword(email, password)
      .then(UserCredential => {
        if (UserCredential) {
          functions.httpsCallable('updateCountry')()
        }
      })
      .catch(error => {
        if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found") {
          this.setState({ error_wrong_password: true });
        } else {
          this.setState({ unknown_error: true });
        }
      });


    this.setState({ email: "", password: "", from: "/home" });
  };

  render() {
    const { email, password, error_email, error_password, error_wrong_password, unknown_error } = this.state;

    return (
      <form className="Login" onSubmit={this.handleSubmit}>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-envelope"></i>
              </div>
            </div>
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              autoComplete="on"
              value={email}
              onChange={this.handleChange}
            />
          </div>
          {error_email ? (
            <small className="form-text text-danger">
              Please enter a valid email address.
            </small>
          ) : null}
        </div>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-lock"></i>
              </div>
            </div>
            <input
              type="password"
              className="form-control"
              name="password"
              id="password"
              autoComplete="on"
              value={password}
              onChange={this.handleChange}
            />
          </div>
          {error_password ? (
            <small className="form-text text-danger">
              Password requires at least 6 characters.
            </small>
          ) : null}
        </div>
        <div className="d-flex justify-content-end mb-4">
          <Link className="small link-muted" to="/forgotpassword">
            Forgot Password?
          </Link>
        </div>
        <div className="mb-2">
          <button
            type="submit"
            className="btn btn-block btn-sm btn-primary transition-3d-hover mb-2"
          >
            Sign In
          </button>
        </div>
        {error_wrong_password ? (
          <small className="form-text text-danger">
            Username and/or password were not correct
          </small>
        ) : null}
        {unknown_error ? (
          <small className="form-text text-danger">
            An unknown error occurred. Please contact us for assistance.
          </small>
        ) : null}
      </form>
    )
  }
}

export default withRouter(withUser(EmailLogin));
