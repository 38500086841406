import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { collectIdsAndDocs } from '../utilities';

const FAQs = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const faqRef = firestore.collection('faq').orderBy('sequence', 'asc');
    faqRef.get().then(query => {
      const items = query.docs.map(collectIdsAndDocs);
      setItems(items);
    });
  }, []);

  return (
    <div>
      <header className="text-center bg-light space-2">
        <h1>General Questions</h1>
      </header>

      <div className="container py-10">
        {items.map(item => (
          <div key={item.header} className="mb-5">
            <h3 className="text-primary">{item.header}</h3>
            <div dangerouslySetInnerHTML={{ __html: item.body }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
