import React from 'react';
// import { Link } from 'react-router-dom';

// assets
import headerHeadphone from '../assets/headers/boy-girl-writing.jpeg';
import earth from '../assets/photos/earth.png';
import aboutUsHdrIcon from '../assets/icons/about-us-hdr-icon.svg';

import NotificationModal from '../components/NotificationModal';

import './About.scss';

const AboutPage = () => (
  <section>
    <header
      className="w-100 hdr-img"
      style={{
        backgroundImage: `url(${headerHeadphone})`,
        backgroundSize: 'cover',
        height: 330,
        backgroundPositionY: -20,
        backgroundRepeat: 'no-repeat'
      }}
    >
      {/* <img src={headerHeadphone} alt="Headphones" className="w-100" /> */}
      <img
        src={aboutUsHdrIcon}
        alt={"Storier: Noun. A person who tells stories."}
        className="hdr-icon"
      />
    </header>

    <div className="container py-4 about-block">
      <h2 className="mt-4 mx-auto text-blue">To our fellow Storiers,</h2>
      <p className="lead mx-auto mt-3">
        Storier was created for the families who care deeply about the content
        their young children consume, yet who are dissatisfied (and disgusted)
        with the way large media companies target kids and fail to deliver
        guaranteed-to-be-kid-safe content through screen-free mediums. Our
        product is an audio content provider built 100% for kids. Parents can
        know with certainty that their kids will not stumble upon any graphic or
        vulgar content, but still have access to thousands of hours of
        educational and entertaining audio content. And now, because we only
        have audio content, the kids never have to look at a screen! You simply
        navigate to their favorite book, episode or music, press play, and let
        their imaginations run wild!
      </p>
      <p className="lead mx-auto mb-5">
        Unlike existing content platforms, Storier is laser-focus on keeping
        your kids safe and doing so in a way that benefits you (the parent) too.
        Specifically, you will find endless content that you'll finally be
        excited to turn on. Gone are the days of feeling guilty when you let
        your kids watch another show. And don't worry, with Storier, your kids
        really will be safe: we are far more worried about their well-being than
        we are about generating more revenue through business models that have
        no place in a kid's world.
      </p>

      <p className="lead mx-auto">The Storier mission is this:</p>
      <h2 className="text-blue mx-auto">
        To create a safe place for kids to learn, laugh, imagine, and connect
        through storytelling.
      </h2>
      <p className="lead mx-auto mt-3">
        Of the verbs listed in our mission, "connect" is probably the least
        intuitive, but it is definitely not the least important. When we started
        working on Storier, we discovered that the percent of families who read
        bedtime stories with their kids is declining at an alarming rate. That
        broke our hearts. There was a missed opportunity for human connection.
        Through a separate study, we also discovered that 74% of kids who
        listened to podcasts initiated discussion with their parents about what
        they had heard. Can you imagine how that might change a family? We've
        since put this to the test and have found that all audio content (books,
        as well as music) brings people together in ways "educational" videos
        and games never could. And hey, if your kids are a part of the minority
        who don't initiate a conversation about what they are listening to,
        that's okay, just ask them yourselves! We think you'll be surprised at
        what they absorb and how you'll be able to connect with them through
        those conversations.
      </p>
      <p className="lead mx-auto">
        As parents ourselves, we are passionate about building a better world
        for our kids. We believe with all our hearts that what we are building
        at Storier can be a key part of the solution, and we will do everything
        within our power to continue to improve the way it serves our kids. In
        addition to directly serving the kids who listen to Storier, we are
        committed to donating 1% of our revenue to charities that align with our
        mission. We believe the world changes one story at a time.
      </p>
      {/*  You can learn more about our giving initiatives <Link to="/giving" className="text-blue">here</Link> */}
      <p className="lead mx-auto">
        We are incredibly excited to embark on this adventure, and we invite you
        to join us. <NotificationModal btnText="Enter your email" btnClass="link-btn" /> to be notified when you can subscribe.
      </p>
      <p className="lead mx-auto">Happy listening,</p>
      <p className="lead mx-auto pb-5">The Storier Founding Team</p>

    </div>
    <div className="gradient pb-5">
      <div className="we-are-proud-section mx-auto">
        <div className="section_text we-are-proud-text font-size-2 font-weight-bold p-4">
          We are proud to be a distributed team, sitting throughout Utah, Texas,
          Colorado, Oregon, and Cambridge (UK).
        </div>
        <div className="earth-icon">
          <img
            src={earth}
            alt="Earth"
          />
        </div>
      </div>

      <h2 className="text-white mx-auto text-center pt-10">
        We want to hear from you!
      </h2>
      <p className="lead mx-auto mt-3 mb-5 hear-from-you-text">
        If you have any questions, feedback, are looking to collaborate, or just
        want to say hello, please contact us. We can’t wait to hear from you!
      </p>
      <div className="mt-4 text-center">
        <a
          href="mailto:hello@storier.fm"
          target="_blank"
          rel="noopener noreferrer"
          className="btn-main"
        >
          Contact Us
        </a>
      </div>
    </div>
  </section>
);

export default AboutPage;
