import React, { Component } from "react";
import { Link } from "react-router-dom";
import { forgotPassword } from "../firebase";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

class ForgotPassword extends Component {
  state = { email: "" };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { email } = this.state;

    await forgotPassword(email);

    this.setState({ email: "", error_email: false });

    MySwal.fire("Password Reset Link Sent!", "Please check your email inbox and spam folder for a link to reset your password.", "success");

  };

  render() {
    const { email } = this.state;
    return (
      <section className="p-md-9 p-2">
        <div className="w-md-40 mx-md-auto">
          <form onSubmit={this.handleSubmit}>
            <div className="mb-5">
              <h1 className="h3 text-primary font-weight-normal mb-0">
                Forgot your{" "}
                <span className="font-weight-semi-bold">password?</span>
              </h1>
              <p>
                Enter your email address below and we'll get you back on track.
              </p>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="email">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                autoComplete="on"
                placeholder="Email address"
                aria-label="Email address"
                required
                value={email}
                onChange={this.handleChange}
              />
            </div>
            <div className="row align-items-center mb-5">
              <div className="col-4 col-sm-6">
                <Link className="small link-muted" to="/login">
                  Back to sign in
                </Link>
              </div>

              <div className="col-8 col-sm-6 text-right">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary transition-3d-hover"
                >
                  Request Reset Link
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default ForgotPassword;
