import React, { Component, createContext } from "react";
import { auth, getUserDocument } from "../firebase";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  state = {
    user: null
  };

  constructor(props) {
    super(props);
    auth.onAuthStateChanged(async user => {
      if (user) {
        const userRef = await getUserDocument(user.uid);
        userRef.onSnapshot(snapshot => {
          this.setState({ user: { uid: snapshot.id, ...snapshot.data() } });
        });
      }
    });
  }

  render() {
    const { user } = this.state;
    const { children } = this.props;

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  }
}

export default UserProvider;
