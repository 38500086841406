import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { firestore } from '../firebase';
import { collectIdsAndDocs } from '../utilities';
import SeriesGrid from './series/SeriesGrid';

import './CuratedList.scss'

const CuratedList = ({ curatedList, scrollPos }) => {
  const [series, setSeries] = useState([]);
  const listRef = useRef();

  useEffect(() => {
    const getSeries = async () => {
    
      const seriesRaw = await Promise.all(
        curatedList.seriesList.slice(0, 6).map(async (seriesId) => {
          const serieRef = await firestore
            .collection('series')
            .doc(seriesId)
            .get()
          
          if (serieRef.exists) {
            return collectIdsAndDocs(serieRef);
          }
        })
      );

      setSeries(seriesRaw);
    }

    getSeries();
  }, [curatedList]);

  useEffect(() => {
    let current = listRef.current;

    if (window.innerWidth < 980) {
      current.style.transform = `scale(1)`;
      return;
    }

    const maxDist = window.innerHeight;
    const minDist = 0;

    const minWidth = .5;
    const maxWidth = 1;

    const myCenter = current.offsetTop + current.offsetHeight-100;
    let centerDiff = Math.abs(scrollPos - myCenter);

    if (centerDiff > maxDist) {
      centerDiff = maxDist;
    } else if (centerDiff < minDist) {
      centerDiff = minDist;
    }

    let width = 1 - (Math.abs(centerDiff - minDist) / (maxDist - minDist));
    width = width * (maxWidth - minWidth) + minWidth;

    if (width > .928) {
      width = maxWidth;
    } else {
      width = .8;
    }

    current.style.transform = `scale(${width})`;
  }, [scrollPos]);

  return (
    <Container
      className="curated-list"
      ref={listRef}
    >
      <h3 className="text-purple">{curatedList.label}</h3>
      <SeriesGrid seriesList={series} cols={4.8} />
    </Container>
  )
}

export default CuratedList;