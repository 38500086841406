import React, { useState } from 'react';

// Firebase
import { firestore } from "../firebase";

// Bootstrap
import Modal from 'react-bootstrap/Modal';

// Swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import './NotificationModal.scss'; 

const MySwal = withReactContent(Swal);

const NotificationModal = ({btnText, btnClass}) => {

    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) return;

        const emailListEntry = {
            email,
            createdAt: new Date().toISOString()
        };

        setShow(false);
        MySwal.fire("Thank you!", "Stay tuned for Storier.FM!", "success");

        firestore.collection("launchEmailList").add(emailListEntry);

        setEmail('');
    };

    return (
        <>
            <button 
                className={btnClass}
                onClick={() => setShow(true)}
            >
                {btnText}
            </button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
            >
                <Modal.Header 
                    closeButton 
                    className="modal-hdr"
                >
                    <h2>
                        Thanks for your interest!
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="body-text mt-3 mx-2">
                        Storier will be launching in August 2021. 
                        Enter your email below to get notified when we launch.
                    </div>

                    <form onSubmit={(e) => handleSubmit(e)} className="AddEmail">
                        <div className="input-group mb-3 mt-5">
                            <input
                                type="email"
                                placeholder="Your email"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-primary">
                                    <i className="fas fa-paper-plane" />
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NotificationModal;