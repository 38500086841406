import React, { Component } from "react";
import { firestore } from "../firebase";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class AddEmail extends Component {
  state = { email: "" };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //TODO: validate email is not malformed
  handleSubmit = e => {
    e.preventDefault();

    const { email } = this.state;

    const emailListEntry = {
      email,
      createdAt: new Date().toISOString()
    };

    MySwal.fire("Thank you!", "Stay tuned for Storier.FM news!", "success");

    firestore.collection("emailList").add(emailListEntry);

    this.setState({ email: "" });
  };

  render() {
    const { email } = this.state;
    return (
      <form onSubmit={this.handleSubmit} className="AddEmail">
        <div className="input-group mb-3">
          <input
            type="email"
            placeholder="Your email"
            className="form-control"
            name="email"
            value={email}
            onChange={this.handleChange}
          />
          <div className="input-group-append">
            <button type="submit" className="btn btn-primary">
              <i className="fas fa-paper-plane" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default AddEmail;
