import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import qs from 'qs';
import withUser from '../components/withUser';
import EmailLogin from '../components/EmailLogin';
import { SIGNUP_ENABLED } from '../remote-config-keys';
import withRemoteConfig from '../components/withRemoteConfig';

import "./Login.scss";

class LoginPage extends Component {
  state = {
    from: { pathname: '/home' },
  };

  isCreatorFlow() {
    const { accountType } = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

    return accountType === 'creator';
  }

  getSignupContent() {
    if (!this.props.remoteConfig[SIGNUP_ENABLED]) {
      return (
        <div className="alert alert-warning mt-4">
          We're sorry! Sign-ups are still closed while we develop the website.
          To find out how to stay tuned or to connect with with us, visit our{' '}
          <Link to="/">homepage</Link>!
        </div>
      );
    }

    return (
      <div>
        <div className="text-center mt-4">
          <span className="small text-muted">Don't have an account?</span>{' '}
          <a
            className="js-animation-link small"
            href="/subscribe"
            data-target="#login"
            data-link-group="idForm"
            data-animation-in="slideInUp"
          >
            Sign Up
          </a>
        </div>
      </div>
    );
  }

  componentDidMount() {
    //Returns to where it was called from
    const locationState = this.props.location.state;

    if (locationState && locationState.from) {
      this.setState({ from: locationState.from });
    }
  }

  render() {
    const { from } = this.state;
    const { user } = this.props;
    if (user) {
      if (this.isCreatorFlow()) return <Redirect to={{ pathname: '/creator' }} />;

      return <Redirect to={{ ...from }} />;
    };

    return (
      <section className="p-md-4 p-4 pb-11 pb-md-0 my-10 my-md-7 login-page">
        <div className="mx-auto w-xl-30 w-lg-40 w-md-60 w-sm-80">
          <div>
            <header className="text-center mb-5">
              <h2 className="h4 mb-0">Welcome Back!</h2>
              <p>Log back into Storier.</p>
            </header>
            <div className="Login">
              <EmailLogin />
              {/* <div className="text-center">
                <span className="u-divider u-divider--xs u-divider--text mb-3">
                  OR
                </span>
              </div>
              <div className="third-party-signin">
                <button
                  type="button"
                  className="btn btn-block mr-1 btn-outline-secondary btn-third-party-signin"
                  onClick={() => signInWithApple(this.props.history)}
                >
                  <i className="fab fa-apple mr-2" />
                  Apple
                </button>
                <button
                  type="button"
                  className="btn btn-block mr-1 btn-outline-secondary btn-third-party-signin"
                  onClick={() => signInWithFacebook(this.props.history)}
                >
                  <i className="fab fa-facebook-square mr-2" />
                  Facebook
                </button>
                <button
                  type="button"
                  className="btn btn-block mr-1 btn-outline-secondary btn-third-party-signin"
                  onClick={() => signInWithGoogle(this.props.history)}
                >
                  <i className="fab fa-google mr-2" />
                  Google
                </button>
              </div> */}
              {this.getSignupContent()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(withRemoteConfig(withUser(LoginPage)));
