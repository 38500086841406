import React, { useState, useEffect, useRef, useCallback } from "react";
import CuratedList from '../components/CuratedList';
import { firestore } from '../firebase';
import { collectIdsAndDocs } from '../utilities';

const CuratedListContainer = () => {
  const [curatedLists, setCuratedLists] = useState([]);
  const [scrollCenter, setScrollCenter] = useState(0);
  const containerRef = useRef();

  useEffect(() => {
    const getCuratedLists = async () => {
      const docs = (await firestore
        .collection('curatedLists')
        .orderBy("order")
        .limit(5)
        .get()).docs.map(collectIdsAndDocs);

      setCuratedLists(docs);
    }

    getCuratedLists();
  }, []);

  const resize = useCallback(
    () => {
      const container = containerRef.current;
      if (container) {
        const windowHeight = window.innerHeight;
        const containerBottom = container.offsetTop + container.offsetHeight;

        setScrollCenter(clamp(window.scrollY + windowHeight / 2, container.offsetTop + 100, containerBottom));
      }
    },
    [containerRef]
  );

  useEffect(() => {
    resize();
    document.addEventListener('scroll', resize);
    
    return () => {
      document.removeEventListener('scroll', resize)
    }
  }, [resize]);

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  return (
    <div ref={containerRef}>
      {
        curatedLists.map((curatedList) => <CuratedList key={curatedList.id} curatedList={curatedList} scrollPos={scrollCenter} />)
      }
    </div>
  );
}

export default CuratedListContainer;