import React from 'react';
import { Link } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import AddEmail from './AddEmail';
import Socials from "./Socials";
import { APP_STORE_LINK, GOOGLE_STORE_LINK } from '../utilities';

const getYear = () => {
    const d = new Date();
    return d.getFullYear();
};
const Footer = () => (
    <footer>
        <div className="bg-light py-5 border-top border-bottom">
            <Container>
                <Row>
                    <div className="col-md-6 offset-md-3">
                        <div className="text-center pt-3 pb-2">
                            <h3 className="mb-4 h4">Sign up for the Storier newsletter</h3>
                            <AddEmail />
                            <Socials />
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
        <div className="py-5">
            <Container>
                <Row>
                    <div className="col-md-6">
                        <div className="py-4">
                            <h3 className="h4 mb-3">More Information</h3>
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-unstyled">
                                        <li className="text-muted">
                                            <Link to="/faq">F.A.Q.</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/creators">Creators</Link>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-unstyled">
                                        <li>
                                            <a
                                                href="https://www.audiokid.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                AudioKid Blog
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.storierstudios.com/home"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Storier Studios
                                            </a>
                                        </li>
                                        <li>
                                            <Link to="/storier-site-map">Site Map</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="py-4">
                            <h3 className="h4 mb-3">Download Storier.FM</h3>
                            <div>
                                <a
                                    className="btn btn-dark btn-large mr-1 mb-1"
                                    href={APP_STORE_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-apple mr-1 fa-lg" /> Apple Store
                                </a>
                                <a
                                    className="btn btn-dark btn-large mr-1 mb-1"
                                    href={GOOGLE_STORE_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-google-play mr-1 fa-lg" /> Google Play
                                </a>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
        <hr className="px-4" />
        <div className="py-5">
            <Container>
                <Row>
                    <div className="col-md-6">
                        <div>
                            All contents © 2019 - {getYear()} Storier. All rights reserved.
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-right">
                            <a href="/terms" className="mr-3">
                                Terms of Service
                            </a>
                            <a href="/privacy" className="mr-3">
                                Privacy Policy
                            </a>
                            <a href="/content-requirements" className="mr-3">
                                Content Requirements
                            </a>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    </footer>
);

export default Footer;
