import React, { useEffect, useState, useContext, useRef } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { useDebounce } from 'use-lodash-debounce'
import Container from "react-bootstrap/Container";

import SeriesGrid from '../components/series/SeriesGrid';
import { getSearchKey } from '../utilities';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { APP_STORE_LINK, GOOGLE_STORE_LINK } from '../utilities';

import { UserContext } from "../providers/UserProvider";

import './Library.scss';

const LibraryPage = () => {
    const user = useContext(UserContext);
    const contentRef = useRef();

    const [searchError, setSearchError] = useState(false);
    const [searchIndex, setSearchIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedPlanType, setSelectedPlanType] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const [searchResults, setSearchResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [showMessageNoResults, setShowMessageNoResults] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);

    const getSearchIndex = async () => {
        const searchKey = await getSearchKey()
        
        if (searchKey) {
            const client = algoliasearch(window.ALGOLIA_APP_ID, searchKey);
            const index = client.initIndex('series');
            setSearchIndex(index);
            return index;
        } else {
            setSearchError(true);
        }
    }

    useEffect(() => {
        if (!searchIndex) return;

        setPage(0);
        setSearchResults([]);
        getPage(searchIndex, 0, true);
    }, [debouncedSearchTerm]); //eslint-disable-line

    useEffect(() => {
        const initialLoad = async () => {
            const index = await getSearchIndex();
            getPage(index, 0);
        }
        initialLoad();
    }, []); //eslint-disable-line

    const getPage = async (index, page, isNewSearch) => {
        const term = debouncedSearchTerm.length > 1 ? debouncedSearchTerm : '*';

        const { hits } = await index.search(term, { page: page, hitsPerPage: 24 });
        if (hits.length >= 1) {
            if (isNewSearch) {
                setSearchResults(hits);
            } else {
                setSearchResults([...searchResults, ...hits]);
            }
            setHasMore(true);
        } else {
            setHasMore(false);
        }

        if (page === 0 && hits.length === 0) {
            setShowMessageNoResults(true);
        } else {
            setShowMessageNoResults(false);
        }
    }

    const loadMore = async () => {
        setPage(page + 1);
        await getPage(searchIndex, page+1);
    }

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (selectedPlanType === 'BASIC') {
            setFilteredResults(searchResults.filter(item => !item.isPremium));
        } else if (selectedPlanType === 'EXTRA' || selectedPlanType === 'SUPER') {
            setFilteredResults(searchResults);
        } else {
            setFilteredResults(searchResults);
        }
    }, [searchResults, selectedPlanType]);

    const toggleSearchFilter = (filterName) => {
        if (selectedPlanType === filterName) {
            setSelectedPlanType('');
        } else {
            setSelectedPlanType(filterName);
        }
    }

    return (
        <section className="library-page">
            <header className="w-100 border-bottom">
                {
                    user &&
                    <div className="welcome-banner">   
                        Welcome to Storier! Download the app on the {" "}
                        <a href={APP_STORE_LINK} target="_blank" rel="noopener noreferrer">Apple App Store</a>, the{" "}
                        <a href={GOOGLE_STORE_LINK} target="_blank" rel="noopener noreferrer">Google Play Store</a> or go to our{" "}
                        <a href={window.APP_SITE}>Web Player</a> to listen now.
                    </div>
                }
                <div className="search-controls-container">
                    <div className="py-5 search-controls">
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Search for your favorite stories"
                            value={searchTerm}
                            onChange={handleChange}
                        />

                        <div className="plan-selectors">
                            <button
                                className={`plan-selector basic ${selectedPlanType === 'BASIC' && 'selected'}`}
                                onClick={() => toggleSearchFilter('BASIC')}
                            >
                                Basic
                            </button>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip className="search-selector-tooltip">
                                    The content included in the Extra and Super plans is the same
                                </Tooltip>}
                            >
                                <div>
                                    <button
                                        className={`plan-selector extra ${selectedPlanType === 'EXTRA' && 'selected'}`}
                                        onClick={() => toggleSearchFilter('EXTRA')}
                                    >
                                        Extra
                                    </button>
                                    <button
                                        className={`plan-selector super ${selectedPlanType === 'SUPER' && 'selected'}`}
                                        onClick={() => toggleSearchFilter('SUPER')}
                                    >
                                        Super
                                    </button>
                                </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                    {searchError && <p className="text-danger">
                        Error Searching!
                    </p>}
                </div>
            </header>

            <div id="content" className="py-5" ref={contentRef}>
                <div className="container pb-4">
                    <h2 className="text-blue">The Library</h2>
                </div>
                <Container>
                    <div
                        className={`animated fadeIn alert alert-soft-primary text-center py-10 ${showMessageNoResults ? '' : 'd-none'
                            }`}
                    >
                        <h3 className="mb-0 font-weight-normal">
                            Sorry! No titles seem to match... try again?
                        </h3>
                    </div>
                    <InfiniteScroll
                        loadMore={async (page) => { await loadMore(page) }}
                        hasMore={hasMore}
                        pageStart={0}
                        initialLoad={false}
                        getScrollParent={() => contentRef}
                    >
                        <SeriesGrid seriesList={filteredResults} />
                    </InfiniteScroll>
                </Container>
            </div>
        </section>
    );
};

export default LibraryPage;
