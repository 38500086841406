import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { analytics } from "../firebase";
import qs from "qs";

const SubscribeCancel = (props) => {

  useEffect(() => {
    const { source, product } = qs.parse(props.location.search, {ignoreQueryPrefix: true});
      if (source === 'stripe') {
      // Landed from stripe, purchase was successful
      analytics.logEvent('subscription_purchase_cancelled', { product });
      props.history.replace('subscribe-plan');
    }
  });

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default withRouter(SubscribeCancel);
