import React from "react";
import spinner from '../assets/storier.gif';

const FullPageSpinner = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div style={{
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1002,
      background: "rgba(255, 255, 255, 0.5",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <div style={{
        width: "300px"
      }}>
        <img src={spinner} alt="Loading" style={{width: "calc(100% + 300px)", marginLeft: "-150px"}}/>
      </div>
    </div>
  )
}

export default FullPageSpinner;