import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { APP_STORE_LINK } from '../utilities';

// Bootstrap
import Collapse from 'react-bootstrap/Collapse';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Assets
import './Subscribe.scss';

const Subscribe = () => {
    return (
        <div className="subscribe-page">

            <div className="plans-section">
                <div className="plans-container">
                    <div className='page-hdr'>
                        <h1>Congrats! Your first 30 days are FREE!</h1>
                        <h3>Select a plan to claim your free trial</h3>
                    </div>
                    <div className="plans-row">
                        <PlanCard
                            planName="super"
                            hdrTxt="Super"
                            bodyContent={
                                <>
                                    <div className='plan-price'>$12 / Month</div>
                                    <ul>
                                        <li style={{ color: '#42B0D2' }}>Everything from the Basic plan</li>
                                        <li style={{ color: '#FFBD00' }}>Everything from the Extra plan</li>
                                        <li>6{" "}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip className="token-tooltip">
                                                    <div>Total of 6 Tokens/Month.</div>
                                                    <div>Tokens are used in exchange for premium audiobooks</div>
                                                    <div>The average premium audiobook costs 4 Tokens.</div>
                                                </Tooltip>}
                                            >
                                                <u>Tokens</u>
                                            </OverlayTrigger>
                                            {" "}per Month to exchange for Premium Stories</li>
                                    </ul>
                                    <div className="body-bottom">
                                        <i className="fas fa-star" /> Best Value <i className="fas fa-star" />
                                    </div>
                                </>
                            }
                            btnContent="GET 30 DAYS FREE"
                        />
                        <PlanCard
                            planName="extra"
                            hdrTxt="Extra"
                            bodyContent={
                                <>
                                    <div className='plan-price'>$9 / Month</div>
                                    <ul>
                                        <li style={{ color: '#42B0D2' }}>Everything from the Basic plan</li>
                                        <li>Premium Stories</li>
                                        <li>4 Tokens per Month to exchange for Premium Stories</li>
                                    </ul>
                                </>
                            }
                            btnContent="GET 30 DAYS FREE"
                        />
                        <PlanCard
                            planName="basic"
                            hdrTxt="Basic"
                            bodyContent={
                                <>
                                    <div className='plan-price'>$6 / Month</div>
                                    <ul>
                                        <li>Ad-Free</li>
                                        <li>Kid-Friendly</li>
                                        <li>No In-App Purchases</li>
                                        <li>Unlimited Music</li>
                                        <li>Unlimited Podcasts</li>
                                        <li>Select Audiobooks</li>
                                        <li>30 Days Free</li>
                                    </ul>
                                </>
                            }
                            btnContent="GET 30 DAYS FREE"
                        />
                    </div>
                    <p className="sub-text">Change plans as you grow. Billing begins after 30 days. Cancel anytime.</p>
                </div>
            </div>

            <div className="faqs">
                <div className='basic-faqs'>
                    <div className="faq-content">
                        <h2>FAQ about the Basic Plan</h2>
                        <Faq
                            header='What is included in the Basic plan?'
                            body={<p>
                                The Basic plan includes unlimited access to music,
                                podcasts, and select audiobooks. As this is the Basic
                                plan, Premium Stories and the Token system are not accessible
                                through this plan.
                            </p>}
                        />
                        <Faq
                            header="Can I see the catalog of what content is included in the Basic plan?"
                            body={<p>
                                Yes. Check out our <Link to="/Library">Library</Link> to see what content is included.
                                You can filter by membership type to make sure the content you are interested in is
                                included in the plan you choose.
                            </p>}
                        />
                        <Faq
                            header="Can I listen to audiobooks as much as I want?"
                            body={<p>
                                Select audiobooks are included in the Basic plan and can be
                                listened to as much as you would like! Most audiobooks, however,
                                are only included with our Premium Stories and are not accessible in
                                the Basic plan.
                            </p>}
                        />
                    </div>
                </div>
                <div className='extra-faqs'>
                    <div className="faq-content">
                        <h2>FAQ about the Extra Plan</h2>
                        <Faq
                            header='What are Premium Stories?'
                            body={<p>
                                Premium Stories are a collection of audiobooks that are only available
                                to users who upgrade to the Extra plan or Super plan. Premium Stories
                                require the user to exchange Tokens in order to add the audiobook to
                                their library. Once a user exchanges their Tokens for a Premium Story,
                                it is theirs to listen to as much as they would like!
                            </p>}
                        />
                        <Faq
                            header='How do I know if I need Tokens to listen to a story?'
                            body={<p>
                                Tokens must be used to add all Premium Stories to your personal
                                library. Premium Stories are all marked with the Storier Token icon
                                and will indicate how many Tokens are required to add the story to
                                your library.
                            </p>}
                        />
                        <Faq
                            header='Do all Premium Stories cost the same amount of Tokens?'
                            body={<p>
                                No. While many stories only cost 1 Token, some audio stories are much
                                longer and more expensive so those stories require more Tokens. The
                                average Premium Audiobook costs 4 Tokens.
                            </p>}
                        />
                        <Faq
                            header='Can I sample a story before I use my Tokens to add it to my library?'
                            body={<p>
                                Yes. A portion of the audio is available for most Premium Stories to
                                help you decide if you want to use your Tokens for that story.
                            </p>}
                        />
                        <Faq
                            header='Can I return a story to get my Tokens back?'
                            body={<p>
                                No. Unfortunately, after you exchange your Tokens for a story you
                                may not trade it back. That's why we will always ask you to confirm
                                that you are sure you want to use your Tokens.
                            </p>}
                        />
                    </div>
                    <div className='super-faqs'>
                        <div className="faq-content">
                            <h2>FAQ about the Super Plan</h2>
                            <Faq
                                header='Are additional stories included in the Super plan?'
                                body={<p>
                                    No. The titles included in the Super plan are currently the
                                    same as those included in the Extra Yellow plan.
                                </p>}
                            />
                            <Faq
                                header="Why is the Super plan the Best Value if it's the most expensive?"
                                body={<p>
                                    The Super plan gives users 6 Tokens every month which can be used
                                    to access the Premium Stories. Those stories are expensive to listen
                                    to in other places so the more Tokens you have, the more stories you can
                                    listen to!
                                </p>}
                            />
                            <Faq
                                header="Do I still get 30 days free?"
                                body={<p>
                                    Yes. You will get a 30-day free trial no matter which plan you choose!
                                </p>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const PlanCard = ({ planName, hdrTxt, bodyContent, btnContent, subContent }) => {
    return (
        <div className={planName}>
            <div className="card">
                <div className="plan-hdr">
                    {hdrTxt}
                </div>
                <div className="plan-body">
                    {bodyContent}
                </div>
                <div className="plan-button">
                    <Link
                        to={{
                            pathname: "/subscribe-steps-1",
                            state: planName
                        }}
                    >
                        {btnContent}
                    </Link>
                    {/* <NotificationModal btnText={btnContent}/> */}
                </div>
            </div>
            <div className="sub-content">
                {subContent}
            </div>
        </div>
    )
}

const Faq = ({ header, body }) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <div className="faq-hdr">
                <div onClick={() => setOpen(!open)} role="button" > {/* //eslint-disable-line */}
                    <i className={open ? "fas fa-caret-down" : "fas fa-caret-right"} />
                    <div>
                        {header}
                    </div>
                </div>
            </div>
            <Collapse in={open}>
                <div className="faq-body" >
                    <div >
                        {body}
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

export default Subscribe;
