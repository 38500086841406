import React from "react";
import { RemoteConfigContext } from "../providers/RemoteConfigProvider";

const withRemoteConfig = Component => {
  const WrappedComponent = props => {
    return (
      <RemoteConfigContext.Consumer>
        {remoteConfig => <Component remoteConfig={remoteConfig} {...props} />}
      </RemoteConfigContext.Consumer>
    );
  };

  return WrappedComponent;
};

export default withRemoteConfig;
