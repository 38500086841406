import React, { useEffect, useState, useContext } from 'react';
import { ModalBody, Modal } from 'react-bootstrap';
import { UserContext } from '../providers/UserProvider';

import './FirstVisitPopUp.scss';

const FirstVisitPopUp = () => {
  const user = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [mountTime, setMountTime] = useState(null);

  useEffect(() => {
    if (!mountTime) {
      setTimeout(() => {
        setMountTime(new Date());
      }, 5000);
      return;
    } else {
      let visited = localStorage['alreadyVisited'];
      if (!visited) {
        if (user === null) {
          setShow(true);
        }
        localStorage['alreadyVisited'] = true;
      }
    }
  }, [user, mountTime]);

  const close = () => {
    setShow(false);
  }

  return (
    <Modal
      show={show}
      onHide={close}
      centered
      id="first-visited-modal"
    >
      <ModalBody>
        <div>
          <h1
            className='text-red'
            style={{
              fontSize: '3rem'
            }}
          >
            We're happy you're here!
          </h1>
        </div>
        <p>
          For a limited time, you will automatically
          get your first month of family-friendly audio
          completely FREE when you create an account!
        </p>
        <button
          className='btn btn-primary'
          onClick={close}
        >
          OK!
        </button>
      </ModalBody>
    </Modal>
  );
}

export default FirstVisitPopUp;