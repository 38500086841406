import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';

// Stripe
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";

// Firebase
import {functions} from "../firebase";

// Swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import FullPageSpinner from "./FullPageSpinner";
import { plansInfo } from '../utilities';

const MySwal = withReactContent(Swal);

const SubscriptionPayment = ({planType, paymentType}) => {
    const history = useHistory();

    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    const [cardError, setCardError] = useState(null);
    const [promoError, setPromoError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [name, setName] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [coupon, setCoupon] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState();
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (!planType || !paymentType) return;

        setProduct(plansInfo[planType][paymentType]);
    }, [planType, paymentType]);

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Quicksand, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "20px",
                fontWeight: 400,
                "::placeholder": {
                    color: "#8c98a4"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleNameChange = e => {
        setName(e.target.value);
        setError(e.target.value && e.target.value.length <= 0);
    };

    const handlePromoCodeChange = e => {
        setPromoCode(e.target.value);
    }

    const handleCardChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setCardError(event.error ? event.error.message : "");
    };

    const subscribeUser = async () => {
        setShowSpinner(true);
        setDisabled(true);
        let errorOcurred = false;
        
        try {
            const createSubscription = functions.httpsCallable('createSubscription');

            const subscriptionData = {
                product: product.productId,
                paymentMethodId: paymentMethod.id,
            }

            if (coupon) {
                subscriptionData['coupon'] = coupon.id;
            }

            const {
                data: { success },
            } = await createSubscription(subscriptionData);

            if (success) {
                MySwal.fire("All Done!", "Your Subscription is complete. Enjoy Storier!", "success");
                history.push('/library');
            } else {
                errorOcurred = true;
            }
        } catch {
            errorOcurred = true;
        }

        if (errorOcurred) {
            setError('An error occurred while trying to submit your account. Please try again. If the error continues please contact Storier for assistance.')
        }

        setDisabled(false);
        setShowSpinner(false);
    }

    const createCard = async (event) => {
        event.preventDefault();

        setShowSpinner(true);
        setDisabled(true);

        if (promoCode) {
            const getCouponFromPromoCode = functions.httpsCallable('getCouponFromPromoCode');
            const result = (await getCouponFromPromoCode({ code: promoCode })).data;
            if (!result.success || !result.coupon) {
                setPromoError('Promo code not recognized. Please provide a valid promo code or leave the field blank.');
                setShowSpinner(false);
                setDisabled(false);
                return;
            }
            setCoupon(result.coupon);
        }

        if (!name || name.length <= 0) {
            setError('Please provide a name');
            setShowSpinner(false);
            setDisabled(false);
            return;
        }

        try {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {name},
            });
            
            setShowSpinner(false);
            
            if (result.paymentMethod) {
                setPaymentMethod(result.paymentMethod);
            } else {
                alert('Error submitting card. Please check all fields are correct.')
            }
        } catch {
            setShowSpinner(false);
            setError(true);
        }

        setDisabled(false);
    };

    const getStartDate = () => {
        const startDate = new Date();
        if (coupon) {
            startDate.setMonth(startDate.getMonth() + coupon.duration_in_months + 2);
        } else {
            startDate.setMonth(startDate.getMonth() + 2);
        }
        return `${startDate.getMonth()}/${startDate.getDate()}/${startDate.getFullYear()}`;
    }

    const isUtah = () => {
        if (!paymentMethod || !paymentMethod.billing_details || !paymentMethod.billing_details.address || !paymentMethod.billing_details.address.postal_code) return false;

        if (paymentMethod.billing_details.address.postal_code.substring(0, 2) === '84') return true;

        return false;
    }

    return (
        <> 
        <FullPageSpinner show={showSpinner} />
        {
            !paymentMethod ? <>
                <form id="payment-form" onSubmit={createCard}>
                    <input
                        type="text"
                        name="displayName"
                        placeholder="Name on Card"
                        autoComplete="on"
                        className="form-control mb-2"
                        value={name}
                        onChange={handleNameChange}
                    />
                    {error && (
                        <div className="form-text text-danger mb-2" role="alert">
                            {error}
                        </div>
                    )}
                    <CardElement id="card-element" options={cardStyle} onChange={handleCardChange} className="form-control mb-2"/>
                    {cardError && (
                        <div className="form-text text-danger mb-2" role="alert">
                            {cardError}
                        </div>
                    )}
                    <input  
                        type="text"
                        name="promoCode"
                        placeholder="Promo Code"
                        className="form-control mb-2"
                        value={promoCode}
                        onChange={handlePromoCodeChange}
                    />
                    {promoError && (
                        <div className="form-text text-danger mb-2" role="alert">
                            {promoError}
                        </div>
                    )}
                    <button
                        disabled={disabled || error || cardError}
                        id="submit"
                        className="btn btn-primary w-100"
                    >
                        <span id="button-text">
                            Review Order
                        </span>
                    </button>
                </form>
            </>
            :
            <>
                <div className="mb-5 p-3 border border-4 border-dark rounded">
                    <p className="font-weight-bold">Subscription Summary</p>
                        <div>Free Trial: <span className="font-weight-bold">{coupon ? `${coupon.duration_in_months + 1} months` : '1 month'}</span></div>
                    <div>Subscription Plan:{" "}
                        <span className="font-weight-bold">
                            ${product.price}/{paymentType === 'monthly' ? 'month' : 'year'} {isUtah() && ` (+$${product.sales_tax} sales tax)`}
                        </span>
                    </div>
                    <div>Start Date: <span className="font-weight-bold">{getStartDate()}</span></div>
                </div>
                <button
                    onClick={subscribeUser}
                    className="btn btn-primary w-100"
                >
                    <span id="button-text">
                        Activate Subscription
                    </span>
                </button>
                {error && (
                    <div className="form-text text-danger mb-2 mt-2" role="alert">
                        {error}
                    </div>
                )}
            </>
        }
        </>
    )
};

export default SubscriptionPayment;
