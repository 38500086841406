import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import StorierSafe from '../components/StorierSafe';
import CircleIcon from '../components/CircleIcon';

// Assets
import headerImg from '../assets/headers/header-boy-pilot.png';
import originals from '../assets/originals.png';
import info_baby from '../assets/photos/ml_by_crib_with_mom.jpg';
import info_toddler from '../assets/photos/info_toddler.jpg';
import info_youngkid from '../assets/photos/info_young.jpg';
import CuratedListContainer from '../components/CuratedListContainer';

import './Landing.scss';

const LandingPage = () => {
    return (
        <section className="landing-page">
            <header
                className="landing-header"
                style={{
                    background: `url(${headerImg}) right center`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                }}
            >
                <div className='landing-hdr-top'>
                    <Link to="/subscribe" className="center btn-purple hdr-btn top">
                        GET 30 DAYS FREE
                    </Link>
                    <div>
                        <h1 className='text-blue' style={{fontSize: 67}}>
                            M<span className='text-yellow'>&#11044;</span>RE
                        </h1>
                        <h1 className='text-blue' style={{fontSize: 47}}>
                            ST<span className='text-red'>&#11044;</span>RIES
                        </h1>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='text-yellow'>
                            <div style={{fontSize: 52}}>LESS</div>
                            <div style={{fontSize: 26.5}}>SCREENS</div>
                        </div>
                        <div className='text-red d-flex flex-column justify-content-end'>
                            <div style={{fontSize: 44, lineHeight: .8}}>NO</div>
                            <div style={{fontSize: 35}}>ADS</div>
                        </div>
                    </div>
                    <Link to="/subscribe" className="center btn-purple hdr-btn bottom">
                        GET 30 DAYS FREE
                    </Link>
                </div>
            </header>
            <div className='sub-header text-blue'>
                <CircleIcon
                    background="#42B0D2"
                    width={75}
                    additionalStyles={{ position: 'absolute', left: 45, top: -150 }}
                />
                <CircleIcon
                    background="#EE2F2C"
                    width={60}
                    additionalStyles={{ position: 'absolute', left: -15, top: -50 }}
                />
                <CircleIcon
                    background="#FFBD00"
                    width={140}
                    additionalStyles={{ position: 'absolute', left: 40, top: 20 }}
                />
                <CircleIcon
                    background="#EE2F2C"
                    width={140}
                    additionalStyles={{ position: 'absolute', right: -50, top: -105 }}
                />
                <CircleIcon
                    background="#FFBD00"
                    width={70}
                    additionalStyles={{ position: 'absolute', right: 80, top: 20 }}
                />
                <CircleIcon
                    background="#42B0D2"
                    width={60}
                    additionalStyles={{ position: 'absolute', right: 10, top: 120 }}
                />
                <h2>AUDIOBOOKS, PODCASTS, AND MUSIC FOR KIDS!</h2>
            </div>

            <Container className="pb-4 explore-the-library-section">
                <CuratedListContainer />
                <div className="my-5 text-center">
                    <Link
                        to="/library"
                        className="explore-btn"
                    >
                        Explore the library
                    </Link>
                </div>
            </Container>

            <div>
                <Container className="py-5 mt-2">
                    <Row className="d-flex align-items-center space-bottom-3">
                        <div className="col-md-6 order-md-1 order-2">
                            <div className="py-4 text-center">
                                <h2 className="h1 text-purple mb-5">
                                    Original stories added all the time
                                </h2>
                                <Link to="/subscribe" className="center btn-purple">
                                    GET 30 DAYS FREE
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 order-md-2 order-1">
                            <img
                                src={originals}
                                alt="Storier Originals"
                                className="p-3"
                                style={{ width: '24rem', maxWidth: '100%' }}
                            />
                        </div>
                    </Row>
                </Container>
            </div>

            <StorierSafe />

            <div id="mockup-block">
                <div className="content-row">
                    <div className="content-col bkgd-blue">
                        <div className="content-col-inner text-white">
                            <h2 className="h4 font-weight-semi-bold">
                                The Early Days
                            </h2>
                            <p className="mb-0">
                                Struggling to help your little one calm down? We’ve been there too.
                                Help your baby fall asleep to the sound of ocean waves or a gentle thunderstorm.
                                Are they losing it in the middle of the day? Turn on some authentic animal sounds
                                or dance to their favorite nursery rhyme - all found in the Storier library.
                            </p>
                            <Link to="/subscribe" className="center btn-white-blue">
                                GET 30 DAYS FREE
                            </Link>
                        </div>
                    </div>
                    <div className="content-col">
                        <img src={info_baby} alt="Baby" />
                    </div>
                </div>
                <div className="content-row">
                    <div className="content-col order-1">
                        <img src={info_toddler} alt="Toddler" />
                    </div>
                    <div className="content-col bkgd-yellow order-2 py-2">
                        <div className="content-col-inner text-white">
                            <h2 className="h4 font-weight-semi-bold">
                                Toddlering
                            </h2>
                            <p className="mb-0">
                                When your wiggly toddler won’t seem to sit still for books,
                                maybe it’s time to try Storier! Toddlers love listening to books.
                                Their brains are sponges and soak in every word (even if it’s the same
                                book over and over again). You’ll be amazed at what they’ll bring up in
                                conversations after listening. Say goodbye to screens and say hello to
                                Storier’s kid-safe, audio-first platform!
                            </p>
                            <Link to="/subscribe" className="center btn-white-yellow">
                                GET 30 DAYS FREE
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="content-row">
                    <div className="content-col bkgd-red">
                        <div className="content-col-inner text-white">
                            <h2 className="h4 font-weight-semi-bold">
                                Young Children
                            </h2>
                            <p className="mb-4">
                                As young children grow, it is important that they flex their creative muscles.
                                Listening to audiobooks and wholesome music on Storier is the perfect way to
                                kickstart that creativity. Without screens, kids put themselves into the
                                action and the stories become real.
                            </p>
                            <Link to="/subscribe" className="center btn-white-red">
                                GET 30 DAYS FREE
                            </Link>
                        </div>
                    </div>
                    <div className="content-col">
                        <img src={info_youngkid} alt="Young Children" />
                    </div>
                </div>
            </div>
            <Link to="/storier-site-map"></Link>
        </section>
    );
};



export default withRouter(LandingPage);
