import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { titleToLink } from '../../utilities';

// Assets
import CoverArt from './CoverArt';
import TokenIcon from '../../assets/icons/token.svg';

import './SeriesCard.scss';

const SeriesCard = props => {
  const { title, id, coverUrl, type, isPremium, colWidth = 4 } = props;
  const [linkTitle, setLinkTitle] = useState('');

  useEffect(() => {
    setLinkTitle(titleToLink(title));
  }, [title]);

  return (
    <div className={`${colWidth && 'col-lg-2'} series-card col-md-3 col-sm-4 col-6 d-flex self-align-stretch mb-3 `}>
      <div
        data-toggle="tooltip"
        data-placement="top"
        title={title}
        className="w-100 text-white series-card-inner"
      >
        <Link to={`/series/${type}/${linkTitle}/${id}`} className="h-100 w-100">
          {
            isPremium ?
              <img
                src={TokenIcon}
                className="token-icon"
                alt="token"
              />
              : null
          }
          <CoverArt url={coverUrl} alt={title} type={type} />
        </Link>
      </div>
    </div>
  );
};

export default SeriesCard;
