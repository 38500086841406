import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { analytics } from "../firebase";
import qs from "qs";

const SubscribeSuccess = (props) => {

  const {search} = props.location;

  useEffect(() => {
    //Remove question mark
    const curQs = search ? search.substring(1) : '';
    const { source, product } = qs.parse(curQs);

    if (source === 'stripe') {
      // Landed from stripe, purchase was successful
      analytics.logEvent('subscription_purchase_success', { product });
      props.history.replace('/account/billing');
    }
  });

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default withRouter(SubscribeSuccess);
