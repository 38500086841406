import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";

import { firestore } from "../firebase";

const ContentRequirements = () => {
  const [richText, setRichText] = useState("");

  useEffect(() => {
    firestore
      .collection("site")
      .doc("content-requirements")
      .get()
      .then(doc => {
        if (doc.exists) {
          const { richText } = doc.data();
          setRichText(richText);
        }
      });
  }, [setRichText]);

  return (
    <Container>
      <div className="space-2">
        <div id="content-requirements" dangerouslySetInnerHTML={{ __html: richText }}></div>
      </div>
    </Container>
  );
};

export default ContentRequirements;
