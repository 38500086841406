import React from "react";
import SeriesCard from "./SeriesCard";

const SeriesGrid = props => {
  const { seriesList, cols } = props;
  return (
    <div className="row d-flex">
      {seriesList
        ? seriesList.map(series => {
          return <SeriesCard key={series.id} {...series} colWidth={cols} />
        })
        : null}
    </div>
  );
};

export default SeriesGrid;
