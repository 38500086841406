import React, {useContext} from 'react';
import {Link, withRouter} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import {UserContext} from '../providers/UserProvider';
// import StorierLogo from '../assets/storier-logo.png';
import AccountMenu from './AccountMenu';

import "./NavBar.scss";

const NAV_LINKS = [
    {label: 'Library', link: '/library', showWhileSubscribed: true},
    // {label: 'Gifts', link: '/gifts', showWhileSubscribed: true},
    {label: 'Subscribe', link: '/subscribe', showWhileSubscribed: false},
    {label: 'About Us', link: '/about', showWhileSubscribed: true},
];

const getLoginButton = (user, props) => {
    if (!user) {
        return (
            <Nav.Item>
                <Link to={{pathname: '/login', state: {from: props.location}}}>
                    <Button className="mr-2 my-auto login-btn">
                        LOG IN
                    </Button>
                </Link>
            </Nav.Item>
        );
    }
};

const getMyAccountButton = user => {
    if (user) {
        return <AccountMenu user={user}/>;
    }
};

const getWebPlayerButton = user => {
    if (user && user.private && user.private.subscriptionActive) {
        return (
                <a href={window.APP_SITE} className="nav-link" >
                    Web Player
                </a>
        );
    }
};

const getDownloadTheAppButton = () => {
    return (
        <a href={'https://linktr.ee/storier'} className="nav-link">
            Download the App
        </a>
    );
}

const NavBar = props => {
    const user = useContext(UserContext);

    const navLinks = NAV_LINKS.filter(link => {
        return (
            link.showWhileSubscribed ||
            (!link.showWhileSubscribed &&
                (!user || !user.private || user.private.isSubscriptionActive)) ||
            (link.showWhileSubscribed &&
                (!user || !user.private || !user.private.isSubscriptionActive))
        );
    });

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="white" fixed="top" className="storier-navbar">
                <Navbar.Brand>
                    <Link to="/home">
                        <img src="/icons/img/storier-logo.png" alt="Storier" style={{maxWidth: 96}}/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        {navLinks.map(n => {
                            return (
                                <Link to={n.link} key={n.label} className={`nav-link ${n.link === window.location.pathname ? 'nav-active' : ''}`}>
                                    {n.label}
                                </Link>
                            );
                        })}
                        {getWebPlayerButton(user)}
                        {getDownloadTheAppButton()}
                        {getLoginButton(user, props)}
                        {getMyAccountButton(user)}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

export default withRouter(NavBar);
