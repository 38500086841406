import React, {useEffect, useState, useContext} from 'react';
import { useHistory } from 'react-router-dom';

// Components
import SignUp from '../components/Signup';
import { UserContext } from "../providers/UserProvider";

// Assets
import './SubscribeSteps.scss';
import stepTwoCircles from '../assets/subscribing/stepTwoCircles.svg';
import qs from "qs";

const SubscribeStepTwo = ({location}) => {
    const history = useHistory();
    const [planType, setPlanType] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const user = useContext(UserContext);
    
    useEffect(() => {
        const { planType, paymentType } = qs.parse(location.search, {ignoreQueryPrefix: true});

        if (!planType || !paymentType) {
            history.push('/subscribe');
        }

        if (user) {
            history.push({
                pathname: '/subscribe-steps-3',
                search: `?planType=${planType}&paymentType=${paymentType}`
            });
        }

        setPlanType(planType);
        setPaymentType(paymentType);

    }, [location, history, user]);

    const onSubmit = () => {
        history.push({
            pathname: '/subscribe-steps-3',
            search: `?planType=${planType}&paymentType=${paymentType}`
        });
    };

    return (
        <div className="content px-3">
            <div className={`hdr basic`}>
                <div className="circles-svg">
                    <img src={stepTwoCircles} alt="Step 2 graphic" />
                </div>
            </div>

            <SignUp onSubmit={onSubmit}/>
        </div>
    )
};

export default SubscribeStepTwo;