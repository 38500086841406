import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { firestore } from '../../firebase';
import { APP_STORE_LINK, collectIdsAndDocs, GOOGLE_STORE_LINK, titleToLink } from '../../utilities';
import CoverArt from './CoverArt';
import withUser from '../withUser';
import parse from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";

import appIcon from '../../assets/icons/app-icon.png';
// import seriesFooter from '../../assets/icons/series-footer.png';
import './Series.scss';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

const Series = (props) => {  
  let { seriesId, title, seriesType } = useParams();
  
  const [pageTitle, setpageTitle] = useState('');
  const [series, setSeries] = useState({});
  const [duration, setDuration] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [showShortDesc, setShowShortDesc] = useState(true);
  const [isbn, setIsbn] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1090);
  const [width, setWidth] = useState(window.innerWidth);
  var showReadMore = true;

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const getData = async () => {
      const seriesRaw = await firestore
        .collection('series')
        .doc(seriesId)
        .get();

      let currentSeries = collectIdsAndDocs(seriesRaw);
      let typ = currentSeries.type;
      typ = typ.charAt(0).toUpperCase() + typ.slice(1);

      const pageLink = `${typ}/${titleToLink(currentSeries.title)}/${seriesId}`;
      if (!title || !seriesType) {
        window.location.replace(pageLink);
      }

      // else {
      //   const link = document.createElement('link');
      //   link.href = pageLink;
      //   link.rel = 'canonical';
      //   document.getElementsByTagName('head')[0].appendChild(link);
      // }


      setpageTitle(`${currentSeries.title} | Children's ${typ} by ${currentSeries.author} | Listen Free`);
      // document.title = `${currentSeries.title} | Children's ${typ} by ${currentSeries.author} | Listen Free`;



      try {
        const seriesCosts = await firestore
          .collection('seriesCosts')
          .doc(seriesId)
          .get();

        currentSeries = {
          ...currentSeries,
          ...seriesCosts.data()
        }

      } catch (err) {
        console.error(err);
      }

      setSeries(currentSeries);
      setIsbn(currentSeries.isbn13 || currentSeries.isbn10 || currentSeries.isbn || '');

      if (currentSeries.storier) {
        let ageGroupStr = '';
        switch (currentSeries.storier['age-group']) {
          case 'ages-0-to-1':
            ageGroupStr = '0-1';
            break;
          case 'ages-2-to-3':
            ageGroupStr = '2-3';
            break;
          case 'ages-3-to-5':
            ageGroupStr = '3-5';
            break;
          case 'ages-4-to-6':
            ageGroupStr = '4-6';
            break;
          case 'ages-6-to-8':
            ageGroupStr = '6-8';
            break;
          case 'ages-7-to-12':
            ageGroupStr = '7-12';
            break;
          case 'ages-9-to-12':
            ageGroupStr = '9-12';
            break;
          default:
            break;
        }
        if (ageGroupStr) {
          setAgeGroup(`${ageGroupStr} years old`);
        }
      }

      const trackPromises = (currentSeries.tracks || []).map(trackId => {
        return firestore
          .collection('tracks')
          .doc(trackId)
          .get();
      });

      const trackDocs = await Promise.all(trackPromises);
      let tracks = trackDocs.map(collectIdsAndDocs);

      if (currentSeries.type === 'podcast') {
        tracks = tracks.reverse();
      }


      if (tracks) {
        const seconds = tracks.reduce((total, track) => {
          return track.duration ? total + track.duration : total;
        }, 0);

        if (seconds) {
          const sec = parseInt(seconds, 10); // convert to int
          let hours = Math.floor(sec / 3600); // get hours
          let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes

          if (hours || minutes) {
            let timeStr = '';
            if (hours !== 0) {
              timeStr = `${hours} hour${hours !== 1 ? 's' : ''} `;
            }
            if (minutes !== 0) {
              timeStr += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
            }

            setDuration(timeStr);
          }
        }
      }
    }

    getData();
  }, [seriesId, title, seriesType]);

  const resize = () => {
    setIsMobile(window.innerWidth < 1090);
  }

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    }
  })

  const metaDataField = (icon, val, style) => {
    if (!val) return '';

    return (
      <li className="list-group-item" style={{ background: 'none' }}>
        <div className="d-flex align-items-center" style={style}>
          <span
            className="meta-icon"
          >
            <i className={`fas fa-${icon} text-blue`} />
          </span>
          {val}
        </div>
      </li>
    )
  }

  const descEqual = (desc1, desc2) => {
    if (!desc1 || !desc2) {
      return false;
    }
    desc1 = desc1.replace(/<.*>/g, '').replace(/[^a-zA-Z]/g, '');
    desc2 = desc1.replace(/<.*>/g, '').replace(/[^a-zA-Z]/g, '');
    return desc1 === desc2;
  }

  const getDescription = () => {
    if (showShortDesc) {
      if (series.shortDescription && (!descEqual(series.shortDescription, series.description))) {
        return parse(series.shortDescription);
      } else if (series.description) {
        let descDom = new DOMParser().parseFromString(`<div>${series.description}</div>`, "text/html");

        let wordsLeft = 50;
        for (let i = 0; i < descDom.firstChild.children.length; i++) {
          const currentChildLength = descDom.firstChild.children[i].innerHTML.split(' ').length;
          if (wordsLeft <= 0) {
            descDom.firstChild.children[i].remove();
          } else if (wordsLeft < currentChildLength) {
            descDom.firstChild.children[i].innerHTML = descDom.firstChild.children[i].innerHTML.split(' ').slice(0, wordsLeft).join(' ');
          }
          wordsLeft -= currentChildLength;
        }

        if (wordsLeft >= 0) {
          showReadMore = false;
        }

        let domStr = new XMLSerializer().serializeToString(descDom);
        domStr = domStr.replace('<html xmlns="http://www.w3.org/1999/xhtml"><head></head><body>', '');
        domStr = domStr.replace('</body></html>', '');
        return parse(domStr);
      } else {
        showReadMore = false;
      }
    } else {
      return parse(series.description || '');
    }
  }

  const getAppLinkButtons = () => {
    return (
      <div className='d-flex align-items-center app-link-container' style={{ fontFamily: 'Sniglet' }}>
        <button
          className="app-link-button"
          onClick={() => { window.open(APP_STORE_LINK, '_black') }}
        >
          <i className="fab fa-apple" />
          Apple App Store
        </button>
        <button
          className="app-link-button"
          onClick={() => { window.open(GOOGLE_STORE_LINK, '_black') }}
        >
          <i className="fab fa-google-play" />
          <div>
            Google Play Store
          </div>
        </button>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <svg
        width="150"
        height="346"
        viewBox="0 0 150 346"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: 'absolute',
          top: '19rem',
          zIndex: -1
        }}
      >
        <circle cx="-20" cy="80" r="80" fill="#42B0D2" />
        <circle cx="59.5" cy="315.5" r="30.5" fill="#FFBD00" />
        <circle cx="125.5" cy="184.5" r="24.5" fill="#EE2F2C" />
      </svg>
      <svg
        width="187"
        height="402"
        viewBox="0 0 187 402"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: 'absolute',
          top: '6rem',
          right: 0,
          zIndex: -1
        }}
      >
        <circle cx="140" cy="83" r="22" fill="#42B0D2" />
        <circle cx="186.5" cy="321.5" r="80.5" fill="#FFBD00" />
        <circle cx="39" cy="39" r="39" fill="#EE2F2C" />
      </svg>
      <Container className="py-4 series-container">
        <Row>
          <Col md="5">
            <CoverArt url={series.coverUrl} alt={series.title} type={series.type} large={true} />
          </Col>
          <Col md="6">
            <ul className="list-group list-group-flush list-group-borderless mb-4">
              {
                width <= 767 &&
                <div className="my-3">
                  {props.user && props.user.private && props.user.private.subscriptionActive ? (
                    <a
                      className='btn btn-primary transition-3d-hover btn-block'
                      href={`${window.APP_SITE}/series/${seriesId}`}
                    >
                      Open in Web Player
                    </a>
                  ) : (
                    <a
                      className='btn transition-3d-hover btn-block free-trial-btn'
                      href="/subscribe"
                      style={{
                        'borderRadius': '10rem',
                        'fontSize': '1.25rem'
                      }}
                    >
                      <div className='btn-title'>Listen for $0.00</div>
                      <div className='btn-sub'>Billing begins after 30 days. Cancel anytime.</div>
                    </a>
                  )}
                </div>
              }
              {metaDataField('user', series.author ? `Written by ${series.author}` : '')}
              {metaDataField('microphone', series.narrator ? `Narrated by ${series.narrator}` : '')}
              {metaDataField('headphones', series.type && series.type.charAt(0).toUpperCase() + series.type.slice(1))}
              {metaDataField('barcode', isbn ? `ISBN: ${isbn}` : '')}
              {metaDataField('hourglass', duration)}
              {metaDataField('puzzle-piece', ageGroup)}

              <li className="list-group-item">
                <div className="d-flex align-items-center">
                  <span
                    className="meta-icon"
                  >
                    <i className='fas fa-money-bill text-blue' />
                  </span>
                  <span>
                    {
                      series.priceAmount &&
                      <span className='strikethrough cost-data'>{`$${series.priceAmount} `}</span>
                    }
                    <span className='token-data'>{!series.isPremium ? 'Included in All Subscriptions' : `${series.tokenPrice} Tokens in "Extra" and "Super" Plans`}</span>
                  </span>
                </div>
              </li>
            </ul>
            {
              width > 767 &&
              <div className="my-3">
                {props.user && props.user.private && props.user.private.subscriptionActive ? (
                  <a
                    className='btn btn-primary transition-3d-hover btn-block'
                    href={`${window.APP_SITE}/series/${seriesId}`}
                  >
                    Open in Web Player
                  </a>
                ) : (
                  <a
                    className='btn transition-3d-hover btn-block free-trial-btn'
                    href="/subscribe"
                    style={{
                      'borderRadius': '10rem',
                      'fontSize': '1.25rem'
                    }}
                  >
                    <div className='btn-title'>Listen for $0.00</div>
                    <div className='btn-sub'>Billing begins after 30 days. Cancel anytime.</div>
                  </a>
                )}
              </div>
            }
          </Col>
        </Row>
        <Row>
          <Col md="11">
            <h1 className="my-4 series-title">{series.title}</h1>
            <h5 className='series-sub'>
              {series.publisher}
              {
                series.series &&
                <span>
                  {series.publisher && <span> &#9679; </span>}
                  <span>{series.series}</span>
                </span>
              }
            </h5>
            <div className="description">
              {getDescription()}
            </div>
            {
              showReadMore &&
              <button
                className="btn btn-link pl-0"
                style={{ boxShadow: 'none' }}
                onClick={() => { setShowShortDesc(!showShortDesc) }}
              >
                {showShortDesc ? 'Read more...' : 'Read less...'}
              </button>
            }
          </Col>
        </Row>
      </Container>
      <div className="app-promo-banner" style={{ backgroundColor: '#29167D' }}>
        <div
          className='d-flex align-items-center justify-content-center'
          style={{ color: 'white' }}
        >
          <img src={appIcon} alt="App Icon" className="app-icon" />

          <div className='download-app-right'>
            <h2 className='kid-friendly-txt'>
              Download our Kid-Friendly App
            </h2>
            {isMobile && getAppLinkButtons()}
          </div>
        </div>
        {!isMobile && getAppLinkButtons()}
      </div>
      <div
        style={{
        }}
      >

        {/* <img
          src={seriesFooter}
          alt="Footer Icon"
          className="series-footer-icon"
          style={{
          }}
        /> */}
        <LazyLoadImage
          src="/icons/series-footer.png"
          alt="Footer Icon"
          className="series-footer-icon"
          style={{
          }}
        />


      </div>
    </>
  );
};

export default withUser(Series);
