import React, { useState, createContext, useEffect, useContext } from "react";
import { firestore } from "../firebase";
import { collectIdsAndDocs } from "../utilities";
import { UserContext } from "./UserProvider";

const ListenHistoryContext = createContext({
  trackHistory: []
});

const ListenHistoryProvider = props => {
  const user = useContext(UserContext);

  const [{ trackHistory }, setTrackHistory] = useState({
    trackHistory: []
  });

  useEffect(() => {
    const getData = async () => {
      const trackHistoryRaw = (await firestore
        .collection("users")
        .doc(user.uid)
        .collection("trackHistory")
        .orderBy("addedAt", "desc")
        .limit(10)
        .get())
        .docs;

      const trackHistoryPromises = trackHistoryRaw.map(collectIdsAndDocs).map(async item => {
        const { trackId } = item;

        let trackDoc = (await firestore
          .collection("tracks")
          .doc(trackId)
          .get())
          .data();

        if (!trackDoc) {
          return Promise.resolve();
        }

        let seriesDoc = (await firestore
          .collection("series")
          .doc(trackDoc.seriesId)
          .get())
          .data();

        if (!seriesDoc) {
          return Promise.resolve();
        }

        return Promise.resolve({
          ...item,
          ...trackDoc,
          seriesTitle: seriesDoc.title
        });
      });

      let newTrackHistory = await Promise.all(trackHistoryPromises);
      setTrackHistory({ trackHistory: newTrackHistory.filter(historyItem => historyItem !== undefined) });
    };

    if (!user) {
      return;
    }

    getData();
  }, [user]);

  return (
    <ListenHistoryContext.Provider value={{ trackHistory }}>
      {props.children}
    </ListenHistoryContext.Provider>
  );
};

export { ListenHistoryProvider, ListenHistoryContext };
