import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withUser from '../components/withUser';
import { functions, auth, createUserProfileDocument } from '../firebase';
import { Link } from 'react-router-dom';

import './Signup.scss';

class Signup extends Component {
  state = {
    displayName: '',
    email: '',
    retypeEmail: '',
    password: '',
    passwordVerify: '',
    newsletter: true,
    from: '/home',
    error_name: false,
    error_email: false,
    error_retype_email: false,
    error_password: false,
    error_password_verify: false,
    error_request: false,
    error_request_message:
      'Sorry, this function seems to be unavailable at the moment.',
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({
      error_email: false,
      error_password: false,
      error_name: false,
      error_password_verify: false,
      error_retype_email: false
    });

    const { email, retypeEmail, password, displayName, passwordVerify } = this.state;

    if (email.length < 6) {
      this.setState({ error_email: true });
      return;
    }
    if (email !== retypeEmail) {
      this.setState({ error_retype_email: true });
      return;
    }
    if (password.length < 6) {
      this.setState({ error_password: true });
      return;
    }
    if (!displayName) {
      this.setState({ error_name: true });
      return;
    }
    if (password !== passwordVerify) {
      this.setState({ error_password_verify: true });
      return;
    }

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      await createUserProfileDocument(user, {
        displayName,
        preferences: {
          allowPush: true,
          receiveNews: this.state.newsletter,
        },
      });
      await user.updateProfile({ displayName });
      await functions.httpsCallable('updateCountry')();
      this.props.onSubmit();
    } catch (error) {
      this.setState({
        error_request: true,
        error_request_message: error.message,
      });
    }
  };

  render() {
    const {
      displayName,
      email,
      retypeEmail,
      password,
      passwordVerify,
      newsletter,
      error_name,
      error_email,
      error_retype_email,
      error_password,
      error_request,
      error_request_message,
      error_password_verify,
    } = this.state;

    const passwordVerifyError =
      error_password_verify ||
      (password !== passwordVerify && passwordVerify !== '');

    return (
      <section className="mb-5 p-3">
        <div className="mx-auto w-xl-30 w-lg-40 w-md-60 w-sm-80">
          <div>
            <form className="SignUp" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label className="form-label" htmlFor="displayName">
                  Display Name
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="displayName"
                    placeholder="Your name"
                    autoComplete="on"
                    className="form-control"
                    value={displayName}
                    onChange={this.handleChange}
                  />
                  {error_name ? (
                    <small className="form-text text-danger">
                      Please enter your name! We will not give your personal
                      information to anybody.
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <div className="input-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    autoComplete="on"
                    className="form-control"
                    value={email}
                    onChange={this.handleChange}
                  />
                  {error_email ? (
                    <small className="form-text text-danger">
                      Please enter your email address.
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="retypeEmail">
                  Confirm Email
                </label>
                <div className="input-group">
                  <input
                    type="email"
                    name="retypeEmail"
                    placeholder=""
                    className="form-control"
                    value={retypeEmail}
                    onChange={this.handleChange}
                  />
                  {error_retype_email && (
                    <small className="form-text text-danger">
                      Emails do not match.
                    </small>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <div className="input-group">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    autoComplete="on"
                    className="form-control"
                    value={password}
                    onChange={this.handleChange}
                  />
                  {error_password ? (
                    <small className="form-text text-danger">
                      At least 8 characters required.
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="passwordVerify">
                  Verify Password
                </label>
                <div className="input-group">
                  <input
                    type="password"
                    name="passwordVerify"
                    placeholder="Password"
                    autoComplete="off"
                    className="form-control"
                    value={passwordVerify}
                    onChange={this.handleChange}
                  />
                  {passwordVerifyError ? (
                    <small className="form-text text-danger">
                      Passwords must match.
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="js-form-message mb-5">
                <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="termsCheckbox"
                    name="termsCheckbox"
                    required
                    data-msg="Please accept our Terms and Conditions."
                    data-error-class="u-has-error"
                    data-success-class="u-has-success"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="termsCheckbox"
                  >
                    <small>
                      I agree to the{' '}
                      <Link className="link-muted link-blue" to="/terms">
                        Terms and Conditions
                      </Link>
                    </small>
                  </label>
                </div>
                <div className="custom-control custom-checkbox text-muted">
                  <input
                    type="checkbox"
                    name="newsletter"
                    className="custom-control-input"
                    id="checkboxNewsletter"
                    value={newsletter}
                    checked={newsletter === true}
                    onChange={e => {
                      this.handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkboxNewsletter"
                  >
                    <small>Subscribe to our newsletter.</small>
                  </label>
                </div>
              </div>

              <div className="align-items-center mb-2">
                <span className="small text-muted">
                  Already have an account?{' '}
                </span>
                <Link
                  className="small link-blue"
                  to={{
                    pathname: '/login',
                    state: { from: this.props.location },
                  }}
                >
                  Login
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-primary btn-main transition-3d-hover mb-3"
              >
                Continue <i className="fas fa-arrow-alt-circle-right" />
              </button>
              {error_request ? (
                <small className="pt-4 text-danger">
                  {error_request_message}
                </small>
              ) : null}

              {/* <div className="text-center">
                <span className="u-divider u-divider--xs u-divider--text mb-4 mt-4">
                  OR
                </span>
              </div>
              <div className="third-party-signin">
                <button
                  type="button"
                  className="btn btn-block mr-1 btn-outline-secondary btn-third-party-signin"
                  onClick={() => signInWithApple(this.props.history, this.state.newsletter, this.props.onSubmit)}
                >
                  <i className="fab fa-apple mr-2" />
                  Apple
                </button>
                <button
                  type="button"
                  className="btn btn-block mr-1 btn-outline-secondary btn-third-party-signin"
                  onClick={() => signInWithFacebook(this.props.history, this.state.newsletter, this.props.onSubmit)}
                >
                  <i className="fab fa-facebook-square mr-2" />
                  Facebook
                </button>
                <button
                  type="button"
                  className="btn btn-block mr-1 btn-outline-secondary btn-third-party-signin"
                  onClick={() => signInWithGoogle(this.props.history, this.state.newsletter, this.props.onSubmit)}
                >
                  <i className="fab fa-google mr-2" />
                  Google
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(withUser(Signup));
