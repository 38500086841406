import React from 'react';
import Dropdown from "react-bootstrap/Dropdown"
import { useHistory } from "react-router-dom";

import {signOut} from "../firebase";
import './AccountMenu.scss';
// import Conditional from "./Conditional";


const AccountMenu = ({ user }) => {
    const history = useHistory();
    const photoUrl = user.photoURL || 'https://image.flaticon.com/icons/svg/847/847969.svg';

    const doSignOut = () => {
        signOut(() => {
            window.location.href = "/";
        })
    };

    //eslint-disable-next-line
    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <div className="account-menu" ref={ref}>
            <button
                className="u-sm-avatar account-menu-button"
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {
                    user.photoURL ?
                        <img
                            className="img-fluid rounded-circle"
                            src={photoUrl}
                            alt="Avatar"
                        />
                    :
                        <i className="fas fa-user default-account-icon"/>
                }
            </button>
        </div>
    ));

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />

            <Dropdown.Menu align="right">
                <Dropdown.Item eventKey="1" onClick={() => history.push('/account/dashboard')}>Manage Account</Dropdown.Item>
                {/* <Conditional show={user.private && user.private.isCreator}>
                    <Dropdown.Item eventKey="2" onClick={() => history.push('/creator')}>Creators Portal</Dropdown.Item>
                </Conditional> */}
                <Dropdown.Item eventKey="3" onClick={doSignOut}>Log Out</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AccountMenu;
