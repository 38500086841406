import React, { Component } from "react";
import { firestore } from "../firebase";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import headerHeadphone from "../assets/headers/header-headphone.png";
import instagram from "../assets/icons/instagram.svg";

import logo from "../assets/storier-logo.png";

import { SHOW_SPLASH } from '../remote-config-keys';
import withRemoteConfig from "../components/withRemoteConfig";

const Swalr = withReactContent(Swal);

class SplashSite extends Component {
  constructor() {
    super();
    this.ref = firestore.collection("emailList");
    this.state = {
      email: ""
    };
  }

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();

    const { email } = this.state;

    this.ref
      .add({ email, created_at: new Date(Date.now()) })
      .then(() => {
        this.setState({ email: "", created_at: "" });
        this.props.history.push("/");
      })
      .catch(error => {
        console.error("Error adding email: ", error);
      });

    Swalr.fire(
      "✨Thank you!✨",
      "Please stay tuned for Storier news!",
      "success"
    );
  };

  componentDidUpdate() {
    if (!this.props.remoteConfig[SHOW_SPLASH]) {
      this.props.history.push('/home');
    }
  }

  render() {
    const { email } = this.state;
    return (
      <div style={{ position: "relative" }}>

        <header
          className="w-100"
          style={{
            height: "40vh",
            background: `url(${headerHeadphone}) no-repeat center`,
            backgroundSize: "cover"
          }}
        />

        <div
          className="mx-auto pb-10 container-fluid"
          style={{ maxWidth: 720 }}
        >
          <div className="py-5">
            <div className="mb-3 text-center">
              <img
                src={logo}
                className="my-2"
                alt="Storier!"
                style={{ width: 200 }}
              />
            </div>

            <h1 className="h2 text-center text-primary">
              Technology Enabled{" "}
              <span className="font-weight-semi-bold">Story Time</span>
            </h1>
            <p className="py-2">
              We are a subscription based audiobook, podcast, and music platform
              that is 100% catered for kids under the age of 12. “Catered for
              kids” means <strong className="text-primary">ad-free</strong>,
              with no <strong className="text-primary">in-app purchases</strong>
              , and loaded with{" "}
              <strong className="text-primary">
                hundreds of hours of educational and entertaining audible
                content
              </strong>{" "}
              to kickstart creativity.
            </p>
            <p className="py-2">
              Our mission is to promote learning and strengthen relationships by
              making storytime magical.{" "}
            </p>
            <p className="py-2">
              We are launching soon so stay tuned and get ready to join the
              fun!!
            </p>
            <div className="w-md-90 mx-auto mt-5">
              <form
                onSubmit={this.onSubmit}
                className="js-validate js-form-message"
              >
                <label className="sr-only" htmlFor="subscribeEmail">
                  Enter your email address
                </label>
                <div className="input-group input-group-borderless input-group-pill shadow-lg">
                  <input
                    type="email"
                    className="form-control"
                    id="subscribeEmail"
                    placeholder="Enter your email address to stay in the loop!"
                    aria-label="Enter your email address"
                    aria-describedby="subscribeButton"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-white text-primary"
                      type="submit"
                      id="subscribeButton"
                    >
                      <span className="fas fa-paper-plane" />
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="px-2 py-5">
              <a href="https://www.instagram.com/storier/" target="_blank" rel="noopener noreferrer" title="Instagram">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={instagram}
                    alt="Intagram logo"
                    style={{ width: 40 }}
                  />
                  <span className="h4 mb-0 ml-2">@Storier.fm</span>
                </div>
              </a>
            </div>
          </div>

          <h2 className="text-center text-primary">
            We want to hear from you!
          </h2>

          <p className="py-3">
            If you have any questions or feedback, are looking to collaborate,
            want to join the Storier team, or just want to say hello, please
            contact us. We want to hear from you!
          </p>

          <div className="text-center">
            <a
              href="mailto:hello@storier.fm"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-soft-primary btn btn-pill px-5 transition-3d-hover"
            >
              <small className="d-block">Email us at</small>
              hello@mystorier.com
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withRemoteConfig(SplashSite));
