import React from 'react';

const CircleIcon = ({
    value,
    background,
    color,
    width,
    fontSize,
    additionalStyles,
    display = 'inline-block'
}) => {

    const styles = {
        borderRadius: '100%',
        fontSize: fontSize || (width * .8),
        fontFamily: 'Sniglet',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: width,
        height: width,
        backgroundColor: background,
        color: color,
        textAlign: 'center',
        ...additionalStyles
    }

    return (
        <div className='circle-icon' style={{ display: display }}>
            <div style={styles}>
                {value}
            </div>
        </div>
    )
}

export default CircleIcon;