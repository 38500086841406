import React, { Component, createContext } from "react";
import { remoteConfig } from "../firebase";
import { SIGNUP_ENABLED, SHOW_SPLASH } from "../remote-config-keys";

const defaultConfig = {
  [SIGNUP_ENABLED]: true,
  [SHOW_SPLASH]: false
};

export const RemoteConfigContext = createContext({ defaultConfig });

class RemoteConfigProvider extends Component {
  state = {
    remoteConfig: defaultConfig
  };

  componentDidMount() {
    remoteConfig.defaultConfig = defaultConfig;

    remoteConfig.fetchAndActivate()
      .then(activated => {
        if (!activated) {
          console.log('Remote Config Defaults set, however activation was not needed.');
        }

        this.setState({ remoteConfig: {
          [SIGNUP_ENABLED]: remoteConfig.getBoolean(SIGNUP_ENABLED),
          [SHOW_SPLASH]: remoteConfig.getBoolean(SHOW_SPLASH)
        }});
      });
  };

  render() {
    return <RemoteConfigContext.Provider value={this.state.remoteConfig}>{this.props.children}</RemoteConfigContext.Provider>;
  }
}

export default RemoteConfigProvider;
