import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/remote-config";
import "firebase/functions";
import "firebase/analytics";
import { loadStripe } from "@stripe/stripe-js";

firebase.initializeApp(window.FIREBASE_CONFIG_JSON);

const reviewStorageName = window.location.hostname.split('.')[0] === 'storier' ? "storier-review-production" : "storier-review"

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const reviewStorage = firebase.app().storage(`gs://${reviewStorageName}`);
export const remoteConfig = firebase.remoteConfig();
export const functions = firebase.functions();
export const analytics = firebase.analytics();
export const fieldValue = firebase.firestore.FieldValue;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    functions.useEmulator("localhost", 5001);
}

remoteConfig.settings = {
    minimumFetchIntervalMillis: 1800000
};

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope('email');
export const signInWithGoogle = (history, receiveNews, onSubmit) => {
    auth.signInWithPopup(googleProvider)
        .then(async userCredential => {
            console.log('Google user credentials', userCredential.user);
            await createUserProfileDocument({
                ...userCredential.user,
                email: userCredential.additionalUserInfo.profile.email
            }, {
                preferences: {
                    allowPush: true,
                    receiveNews: receiveNews || false
                }
            });

            await functions.httpsCallable('updateCountry')();

            if (onSubmit && (userCredential.additionalUserInfo || {}).isNewUser) {
                onSubmit();
            } else {
                history.push('/library');
            }
        });
};

const fbProvider = new firebase.auth.FacebookAuthProvider();
fbProvider.addScope('email');
export const signInWithFacebook = (history, receiveNews, onSubmit) => {
    auth.signInWithPopup(fbProvider)
        .then(async userCredential => {
            await createUserProfileDocument({
                ...userCredential.user,
                email: userCredential.additionalUserInfo.profile.email
            }, {
                preferences: {
                    allowPush: true,
                    receiveNews: receiveNews || false
                }
            });

            await functions.httpsCallable('updateCountry')();

            if (onSubmit && (userCredential.additionalUserInfo || {}).isNewUser) {
                onSubmit();
            } else {
                history.push('/library');
            }
        });
};

const appleProvider = new firebase.auth.OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');
export const signInWithApple = (history, receiveNews = false, onSubmit) => {
    auth.signInWithPopup(appleProvider)
        .then(async userCredential => {
            await createUserProfileDocument({
                ...userCredential.user,
                email: userCredential.additionalUserInfo.profile.email
            }, {
                preferences: {
                    allowPush: true,
                    receiveNews: receiveNews || false
                }
            });

            await functions.httpsCallable('updateCountry')();

            if (onSubmit && (userCredential.additionalUserInfo || {}).isNewUser) {
                onSubmit();
            } else {
                history.push('/library');
            }
        });
};

export const signOut = callback => {
    auth.signOut();
    callback();
};

export const stripePromise = loadStripe(window.STRIPE_KEY);

export const createUserProfileDocument = async (user, additionalData) => {
    if (!user) return;

    // Get a reference to the location in the Firestore where the user
    // document may or may not exist.
    const userRef = firestore.doc(`users/${user.uid}`);

    // Go and fetch a document from that location.
    const snapshot = await userRef.get();

    // If there isn't a document for that user. Let's use information
    // that we got from either Google or our sign up form.
    if (!snapshot.exists) {
        const { displayName, email, photoURL } = user;
        const createdAt = new Date();
        try {
            await userRef.set({
                displayName,
                email,
                photoURL,
                createdAt,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user", error.message);
        }
    }

    // Get the document and return it, since that's what we're
    // likely to want to do next.
    return getUserDocument(user.uid);
};

export const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        return firestore.collection("users").doc(uid);
    } catch (error) {
        console.error("Error fetching user", error.message);
    }
};

export const updateUserDocument = async (uid, updates) => {
    if (!uid) return null;
    try {
        await firestore.collection("users").doc(uid).update(updates);
    } catch (error) {
        console.error("Error updating user", error.message);
    }
};

export const forgotPassword = async email => {
    await auth.sendPasswordResetEmail(email).catch(err => console.error(err));
};

export const getGift = async (giftCode) => {
    if (!giftCode) return null;
    try {
        return (await firestore.collection("gifts").doc(giftCode).get()).data();
    } catch (error) {
        console.error("Error fetching gift", error.message);
        return null;
    }
};

export default firebase;
